import { Component } from '@angular/core';
import { SubjectArea } from 'src/app/models/subject-area';
import { Category } from 'src/app/models/category';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {

  isExpanded = true;
  //showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  subjectAreas: SubjectArea[] = [];

  currentViewedReportId = '40d736fe-efc2-4a1a-8a7f-b431b469fd54';
  
  constructor(public httpService: HttpService) {}

  ngOnInit(){
    try {
      //get values here
      this.httpService.getSubjectAreas('').subscribe(data => {
        this.subjectAreas = data;
      });
    } catch (error: any) {
      // Prepare status message for Embed failure
   
      const displayMessage = `Failed to fetch config for report. Status: ${error.statusText} Status Code: ${error.status}`;
      console.error(displayMessage);
      return;
    }
  }


  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  showSubmenu(index: number) {
    if(this.subjectAreas)
      this.subjectAreas[index].subMenuIsShown =  !this.subjectAreas[index].subMenuIsShown;
  }

  test(x: SubjectArea){
    return JSON.stringify(x);
  }

  testt(x: Category[]){
    return JSON.stringify(x);
  }

  getIsShown(index: number) {
    return false;
    if(this.subjectAreas)
      return this.subjectAreas[index]?.subMenuIsShown;

    return false;
  }

  setReportId(reportId: string) {
    this.currentViewedReportId = reportId;
  }
}
