<div class="newsInsightsWrapper" *ngIf="!windowsService.isMobileLayout && !windowsService.isMiniMobileLayout">

  <div class="newsInsightsContent">
    <div class="newsInsightsHeader">
      {{ headerName }}
    </div>

    <div class="cardListContainer">
      <mat-card [style.width.%]="90 / (featuredReportList?.length || 1)" appearance="outlined"
        *ngFor="let ni of featuredReportList; index as i">
        <img (click)="routeToUrl(ni.url)" class="sectionHeaderImage" [src]="getImageDir(i)" alt="">
        <mat-card-content>
          <div class="newsInsightsSubHeader">
            {{ni.title}}
          </div>
          <p class="description">
            {{ni.description}}
          </p>
        </mat-card-content>
        <mat-card-actions>
          <a class="learnMoreUrls ctaLink" target="_blank" [href]="ni.url">{{ni.callToAction}}</a>
        </mat-card-actions>
      </mat-card>
    </div>





  </div>

</div>

<div class="newsInsightsWrapper" *ngIf="windowsService.isMobileLayout && !windowsService.isMiniMobileLayout">

  <div class="newsInsightsContent">
    <div class="newsInsightsHeader">
      {{ headerName }}
    </div>


    <div class="cardListContainer">
      <mat-card class="example-card-mobile" appearance="outlined" *ngFor="let ni of getMobileGridRowOne(); index as i">
        <img (click)="routeToUrl(ni.url)" class="sectionHeaderImage" [src]="getImageDir(i)" alt="">
        <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
        <mat-card-content>
          <div class="newsInsightsSubHeader">
            {{ni.title}}
          </div>
          <p class="description-mobile">
            {{ni.description}}
          </p>
        </mat-card-content>
        <mat-card-actions>
          <a class="learnMoreUrls ctaLink" target="_blank" [href]="ni.url">{{ni.callToAction}}</a>
        </mat-card-actions>
      </mat-card>
    </div>


    <div class="cardListContainer">
      <mat-card class="example-card-mobile" appearance="outlined" *ngFor="let ni of getMobileGridRowTwo(); index as i">
        <img (click)="routeToUrl(ni.url)" class="sectionHeaderImage" [src]="getImageDir(i+2)" alt="">
        <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
        <mat-card-content>
          <div class="newsInsightsSubHeader">
            {{ni.title}}
          </div>
          <p class="description-mobile">
            {{ni.description}}
          </p>
        </mat-card-content>
        <mat-card-actions>
          <a class="learnMoreUrls ctaLink" target="_blank" [href]="ni.url">{{ni.callToAction}}</a>
        </mat-card-actions>
      </mat-card>
    </div>





  </div>

</div>






<div class="newsInsightsWrapper" *ngIf="windowsService.isMiniMobileLayout">

  <div class="newsInsightsContent">
    <div class="newsInsightsHeader">
      {{ headerName }}
    </div>

    <!-- <div>
            <p class="messageText">Stay connected with the company that’s connecting the dots with what’s happening in the industry and
                around the world.</p>
        </div> -->

    <div class="newsInsightsRealTimeContent-MiniMobile">


      <div class="cardListContainer-MiniMobile">
        <mat-card [class.mat-elevation-z2]="true" class="example-card-MiniMobile" appearance="outlined"
          *ngFor="let ni of featuredReportList; index as i">
          <img (click)="routeToUrl(ni.url)" class="sectionHeaderImage" [src]="getImageDir(i)" alt="">
          <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
          <mat-card-content>
            <div class="newsInsightsSubHeader">
              {{ni.title}}
            </div>
            <p class="description-mobile">
              {{ni.description}}
            </p>
          </mat-card-content>
          <mat-card-actions>
            <a class="learnMoreUrls ctaLink" target="_blank" [href]="ni.url">{{ni.callToAction}}</a>
          </mat-card-actions>
        </mat-card>
      </div>




    </div>
  </div>

</div>
