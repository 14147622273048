<div class="containerMegaMobile">
  <div class="tools">
    <mat-list role="list">
      <mat-list-item role="listitem"
        ><b>Hi, {{ getName() }}</b></mat-list-item
      >
      <mat-divider></mat-divider>

      <button style="margin: 5px 20px; font-size: 16px" mat-button [matMenuTriggerFor]="menuu" (click)="$event.stopPropagation()">
        <i style="color: gold" class="material-icons">star_rate</i> Favorites<img style="margin-left: 5px" src="../../../assets/images/Gallagherdropicon.svg" alt="" id="rightarrowofmenu" />
      </button>
      <mat-menu #menuu="matMenu" xPosition="after">
        <div style="display: flex; justify-content: left" *ngFor="let report of userService.favoriteReports; index as i" (mouseenter)="setReportVisible(i)" (mouseleave)="setReportNotVisible(i)">
          <button color="accent" (click)="navigateToReportView(report)" style="cursor: pointer; color: #2b5a80; width: 200px" mat-menu-item>{{ report.name }}</button>
          <div matTooltip="Remove Report" matTooltipPosition="right" *ngIf="isVisible(report)" style="line-height: 2.7; margin-right: 5px" (click)="deletefavoriteReport(report)"><b style="cursor: pointer">X</b></div>
        </div>

        <div style="display: flex; justify-content: left">
          <button color="accent" (click)="navigateToManageFavorites()" style="cursor: pointer; color: #2b5a80; width: 200px" mat-menu-item><b>Manage Favorites</b></button>
        </div>
      </mat-menu>

      <mat-divider></mat-divider>

      <button style="margin: 5px 26px; font-size: 16px" mat-button [matMenuTriggerFor]="menuuu" (click)="$event.stopPropagation()">
        Recent Items<img style="margin-left: 5px" src="../../../assets/images/Gallagherdropicon.svg" alt="" id="rightarrowofmenu" />
      </button>
      <mat-menu #menuuu="matMenu" xPosition="after">
        <div style="display: flex; justify-content: left" *ngFor="let report of userService.visitReports; index as i" (mouseenter)="setReportVisible(i)" (mouseleave)="setReportNotVisible(i)">
          <button color="accent" (click)="navigateToReportView(report)" style="cursor: pointer; color: #2b5a80; width: 300px" mat-menu-item>
            <b>{{ i + 1 }}.</b> {{ report.name }}
          </button>
        </div>

        <div style="display: flex; justify-content: left">
          <button (click)="deleteReportVisits()" color="accent" style="cursor: pointer; color: #2b5a80; width: 200px" mat-menu-item><b>Clear Recents</b></button>
        </div>
      </mat-menu>

      <mat-divider></mat-divider>

      <mat-list-item role="listitem" style="cursor: pointer; padding: 0">
        <a
          mat-menu-item
          target="_blank"
          href="https://teams.microsoft.com/l/team/19%3aeFpJ2xNGPlXo2vUHbAVurkuBnU0v0AAiPPCUnd4p5do1%40thread.tacv2/conversations?groupId=3734acb6-368c-47bb-9e6e-d086fd3ea8c6&tenantId=6cacd170-f897-4b19-ac58-46a23307b80a"
          >Support</a
        >
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item role="listitem" style="cursor: pointer; padding: 0" *ngIf="isAdmin$ | async"> <a mat-menu-item [routerLink]="['manage']">Manage</a> </mat-list-item>
      <mat-divider *ngIf="isAdmin$ | async"></mat-divider>
      <mat-list-item role="listitem" style="cursor: pointer; padding: 0"><a mat-menu-item [routerLink]="['']">Home</a></mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item role="listitem" style="cursor: pointer; padding: 0"><a mat-menu-item (click)="logout()">Logout</a> </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
  </div>

  <div class="megaMobile">
    <mat-card class="main-card">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree" #treeSelector>
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
                This padding value depends on the mat-icon-button width. -->
        <mat-tree-node
          [ngClass]="{
            'subject-area-node': isSubjectAreaNode(node),
            'category-node': isCategoryNode(node),
            'subcategory-node': isSubCategoryNode(node),
            'second-subcategory-node': isSubCategoryTwoNode(node),
            'report-node': isDriveReportNode(node),
            'add-node': isAddNewSubjectAreaNode(node)
          }"
          *matTreeNodeDef="let node"
          matTreeNodeToggle
        >
          <div
            [ngClass]="{
              'subject-area-node': isSubjectAreaNode(node),
              'category-node': isCategoryNode(node),
              'subcategory-node': isSubCategoryNode(node),
              'second-subcategory-node': isSubCategoryTwoNode(node),
              'report-node': isDriveReportNode(node),
              'add-node': isAddNewSubjectAreaNode(node)
            }"
            class="mat-tree-node"
          >
            <div class="node-info">
              <img style="max-height: 40px" [src]="node.image" class="preview" />
              <span *ngIf="isSubjectAreaNode(node)"><b>Subject:</b> {{ node.name }}</span>
              <span class="node-info" *ngIf="isSubjectAreaNode(node)"><b>Description:</b> {{ node.description ?? '---' }}</span>
              <span *ngIf="isCategoryNode(node)"><b>Category: </b>{{ node.name }}</span>
              <span *ngIf="isSubCategoryNode(node)"><b>Sub Category:</b> {{ node.name }}</span>
              <span *ngIf="isSubCategoryTwoNode(node)"><b>Second Sub Category:</b> {{ node.name }}</span>
              <span *ngIf="isDriveReportNode(node)" style="cursor: pointer" (click)="navigateToReportView(node)"><b>Report:</b> {{ node.name }}</span>
            </div>
          </div>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <div
            [ngClass]="{
              'subject-area-node': isSubjectAreaNode(node),
              'category-node': isCategoryNode(node),
              'subcategory-node': isSubCategoryNode(node),
              'second-subcategory-node': isSubCategoryTwoNode(node),
              'report-node': isDriveReportNode(node)
            }"
            class="mat-tree-node"
          >
            <div>
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
              </button>
              <img style="max-height: 40px" [src]="node.image" class="preview" />
              <span *ngIf="isSubjectAreaNode(node)"><b>Subject:</b> {{ node.name }}</span>
              <span *ngIf="isCategoryNode(node)"><b>Category: </b>{{ node.name }}</span>
              <span *ngIf="isSubCategoryNode(node)"><b>Sub Category:</b> {{ node.name }}</span>
              <span *ngIf="isSubCategoryTwoNode(node)"><b>Second Sub Category:</b> {{ node.name }}</span>
              <span *ngIf="isDriveReportNode(node)" style="cursor: pointer" (click)="navigateToReportView(node)"><b>Report:</b> {{ node.name }}</span>
            </div>
          </div>

          <!-- There is inline padding applied to this div using styles.
                      This padding value depends on the mat-icon-button width.  -->
          <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
    </mat-card>
  </div>
</div>
