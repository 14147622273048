import { Component, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FeaturedReport, UpdateAdGroupFeaturedReportsRequest } from 'src/app/models/featuredreports';
import { FeaturedReportsServiceService } from 'src/app/services/featured-reports-service.service';

@Component({
  selector: 'app-featured-reports-editor',
  templateUrl: './featured-reports-editor.component.html',
  styleUrls: ['./featured-reports-editor.component.scss']
})
export class FeaturedReportsEditorComponent {
  @Input() featuredReportsList!: FeaturedReport[] | undefined;
  @Input() adGroupId!: string | undefined;
  featuredReportsFormGroup: FormGroup;
  featuredReportsFormArray!: FormArray;




  ngOnChanges(changes: SimpleChanges) {

    if (changes['featuredReportsList'] && changes['featuredReportsList'].currentValue) {
      // debugger;
      const listFeaturereports = changes['featuredReportsList'].currentValue;

      if (listFeaturereports && listFeaturereports.length > 0) {
        this.addFeaturedReports(listFeaturereports);
      } else {
        this.addBlankFeaturedReports();
      }
    }
  }

  isVisible() {
    if (this.adGroupId)
      return true;
    return false;
  }

  ngOnInit(): void {
    if (!this.featuredReportsList || this.featuredReportsList.length < 1) {
      this.addBlankFeaturedReports();
    }

  }

  getControls() {
    const formArray = this.featuredReportsFormGroup.get('featuredReportsFormArray') as FormArray;

    if (formArray) {
      const controls = formArray.controls;
      return controls
    }

    return (new FormArray([])).controls
  }

  constructor(private fb: FormBuilder, private _featuredReportsService: FeaturedReportsServiceService) {
    this.featuredReportsFormGroup = new FormGroup({
      featuredReportsFormArray: new FormArray([])
    });
  }

  addFeaturedReports(incomingListOfFeaturedReports: FeaturedReport[]) {
    this.featuredReportsFormArray = this.featuredReportsFormGroup.get('featuredReportsFormArray') as FormArray;
    this.featuredReportsFormArray.clear()// = this.fb.array([]);

    incomingListOfFeaturedReports.forEach(x => {
      this.featuredReportsFormArray.push(this.fb.group({
        title: new FormControl(x.title, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(45),
        ]),
        description: new FormControl(x.description, [
          Validators.required,
        ]),
        callToAction: new FormControl(x.callToAction, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(45),
        ]),
        url: new FormControl(x.url, [
          Validators.required,
          Validators.minLength(1),
        ]),
        sequence: x.sequence
      }));
    })

  }

  addBlankFeaturedReports() {
    this.featuredReportsFormArray = this.featuredReportsFormGroup.get('featuredReportsFormArray') as FormArray;
    this.featuredReportsFormArray.clear()
    for (let i = 0; i < 3; i++) {
      this.featuredReportsFormArray.push(this.fb.group({
        title: new FormControl('', [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(45),
        ]),
        description: new FormControl('', [
          Validators.required,
        ]),
        callToAction: new FormControl('', [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(45),
        ]),
        url: new FormControl('', [
          Validators.required,
          Validators.minLength(1),
        ]),
        sequence: i
      }));
    }
  }


  formValid() {
    const dirty = this.featuredReportsFormGroup.dirty;
    const formValid = this.featuredReportsFormGroup.valid;
    return dirty && formValid;
  }

  save() {
    this.featuredReportsFormArray = this.featuredReportsFormGroup.get('featuredReportsFormArray') as FormArray;
    const request: UpdateAdGroupFeaturedReportsRequest = {
      featuredReports: this.featuredReportsFormArray.value
    };

    this._featuredReportsService.updateFeaturedReportsbyAdGroupId(this.adGroupId as string, request).subscribe(x => {
      console.log(x);
    })

  }
}
