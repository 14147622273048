<div>
  <label><span *ngIf="required" style="color: red">*</span> {{labelName}}</label>
  <mat-form-field class="field" appearance="outline">

    <div>

      <mat-toolbar>
        <input type="text"
        placeholder="Enter {{labelName}}"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
        (keyup)="onChangeEvent($event)">

        <button *ngIf="isNewEntry()" (click)="addNew()" class="addButton" mat-raised-button>Add</button>
      </mat-toolbar>

    </div>

    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"  (optionSelected)="onSelectionChange($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
    <mat-error >{{labelName}} Required</mat-error>
  </mat-form-field>
</div>





