<div class="reportSearchRow">
  <form class="example-form">
    <mat-form-field appearance="outline">
      <mat-label>Search</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input type="tel" matInput />
    </mat-form-field>
  </form>
</div>

<div class="tableRow">
  <mat-table #table class="mat-elevation-z8" cdkDropList (cdkDropListDropped)="drop($event)" cdkDropListData="dataSource" [cdkDropListDisabled]="dragDisabled" [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="headerCell" [style.background-color]="tableHeaderColor"> Report Name </mat-header-cell>
      <mat-cell *matCellDef="let element; let row; let i = index" [ngClass]="{ highlight: i % 2 == 0 }"> <mat-icon class="dragCursor" (mousedown)="dragDisabled = false">reorder</mat-icon>{{ element.name }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef class="headerCell" [style.background-color]="tableHeaderColor"> Description </mat-header-cell>
      <mat-cell *matCellDef="let element; let row; let i = index" [ngClass]="{ highlight: i % 2 == 0 }"> {{ element.description }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdDate">
      <mat-header-cell *matHeaderCellDef class="headerCell" [style.background-color]="tableHeaderColor"> Created Date </mat-header-cell>
      <mat-cell *matCellDef="let element; let row; let i = index" [ngClass]="{ highlight: i % 2 == 0 }"> {{ element.createdDate }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="reportName">
      <mat-header-cell *matHeaderCellDef class="headerCell" [style.background-color]="tableHeaderColor"> Report </mat-header-cell>
      <mat-cell *matCellDef="let element; let row; let i = index" [ngClass]="{ highlight: i % 2 == 0 }"> {{ element.reportName }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="headerCell" [style.background-color]="tableHeaderColor"> Action </mat-header-cell>
      <mat-cell *matCellDef="let element; let row; let i = index" [ngClass]="{ highlight: i % 2 == 0 }">
        <button mat-button color="primary" (click)="deleteReport(element.id)">X</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements"> </mat-paginator>
</div>

<table-basic-example></table-basic-example>
