import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "../models/app.config";

@Injectable({
    providedIn: 'root'
  })
  export class ConfigService {

    public configuration: AppConfig | undefined;

    constructor(
      private httpClient: HttpClient
    ) {
      this.setConfig();
     }

    setConfig(): void {
      const jsonString = localStorage.getItem("app-config") as string
      const config = JSON.parse(jsonString) as AppConfig;
      this.configuration = config;
    }

    readConfig(): AppConfig | undefined {
      return this.configuration;
    }
  }
