<ng-template #embedReportFlow> </ng-template>

<div style="display: flex; flex-direction: row; justify-content: space-between; height: 25px">
  <app-breadcrumb-viewer *ngIf="!windowsService.isMobileLayout"></app-breadcrumb-viewer>

  <div class="favArea" style="display: flex; justify-content: left">
    <a mat-button style="width: 150px; padding-bottom: 11px" (click)="makeFullScreen()">View Full Screen</a>
    <div *ngIf="!isFavorited()"><i (click)="favoriteReport()" style="cursor: pointer; font-size: 30px; vertical-align: middle; line-height: 0.8" class="material-icons">star_border</i></div>
    <div *ngIf="isFavorited()"><i style="color: gold; cursor: pointer; font-size: 30px; vertical-align: middle; line-height: 0.8" (click)="deletefavoriteReport()" class="material-icons">star_rate</i></div>

    <div style="line-height: 1.6; margin-right: 135px" *ngIf="!isFavorited()">Favorite</div>
    <div style="line-height: 1.6; margin-right: 120px" *ngIf="isFavorited()">Unfavorite</div>
  </div>
</div>

<div class="message" *ngIf="reportTypeNotVisible"><h3>Report Type is not accepted yet.</h3></div>

<div class="message" *ngIf="displayMessage" style="font-size: 25px">
  <h3>{{ displayMessage }}</h3>
</div>

<powerbi-report [embedConfig]="reportConfig" [cssClassName]="reportClass" [phasedEmbedding]="phasedEmbeddingFlag" [eventHandlers]="eventHandlersMap" #report> </powerbi-report>
