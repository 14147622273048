import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ExtractedReport, SeearchGptRequest, SeearchGptResponse } from 'src/app/models/Requests/SearchGptRequest';
import { ConfigService } from 'src/app/services/config.service';
import { HttpService } from 'src/app/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlViewerServiceService } from '../url-viewer/url-viewer-service.service';
import { ChatGpMessage } from 'src/app/models/chat-gp-message';
import { LinkGpButton } from 'src/app/models/link-button-gpmessage';
import { SearchGptServiceService } from 'src/app/services/search-gpt-service.service';
@Component({
  selector: 'app-search-gpt',
  templateUrl: './search-gpt.component.html',
  styleUrls: ['./search-gpt.component.scss'],
})

export class SearchGptComponent {

  messages: ChatGpMessage[] = [];
  linkButtons: LinkGpButton[] = [];

  startText = 'Based on your question and available data, we recommend the following report:'
  intro = "Welcome to Gallagher Drive Recommender, the AI-enabled search feature that recommends Gallagher's Drive Data and Analytics reports, providing powerful insights that drive action to face your future confidently.";
  botWaitingText = 'Thinking about you data...';
  testType = '';

  botMessages: Array<string> = [];
  mes1 = '';
  mes2 = '';
  mes3 = '';
  mes4 = '';
  botcount = 0;

  botMessageCharCount = 0;

  message = '';
  awaitingResponse = false;

  stringInterpolation: string = "Angular Example - Typing Animation Directive (string interpolation)"
  start: boolean = false

  constructor(public httpService: HttpService, private searchGptServiceService: SearchGptServiceService, private configService: ConfigService, private _router: Router, private urlViewrService: UrlViewerServiceService, private route: ActivatedRoute) { }

  form = new FormGroup({
    searchBoxMessage: new FormControl(''),
  });

  ngAfterViewInit() {

  }

  ngOnInit(): void {

    this.mes1 = `<div class='dynamic'> Please wait while Drive Recommender identifies the right data report for your question… </div>`;
    this.mes2 = `<div class='dynamic'> Did you know Gallagher Drive, our premier platform of data and analytics, analyzes market conditions, claims history, and industry benchmarks to give our clients and carriers the data they need to optimize risk management programs?</div>`;
    this.mes3 = `<div class='dynamic'> When used as part of CORE360™, Gallagher Drive creates meaningful insights for maximum impact on your total cost of risk.</div>`;
    this.mes4 = `<div class='dynamic'> Gallagher Drive’s suite of robust data and analytics tools revolves around four key capabilities: `
      + `<ul> <li>	Benchmarking Services </li> <li>	Placement Analytics </li> <li> Claims Analytics </li> <li>	Consulting Services & Solutions </li>  </ul>`;

    this.botMessages.push(this.mes1);
    this.botMessages.push(this.mes2);
    this.botMessages.push(this.mes3);
    this.botMessages.push(this.mes4);

    const hasPrevMessages = this.searchGptServiceService.prevMessages && this.searchGptServiceService.prevMessages.length > 0;
    if (hasPrevMessages) {
      this.messages = this.searchGptServiceService.prevMessages;
      this.linkButtons = this.searchGptServiceService.prevLinkButtons;
    }

    const lastSearch = this.httpService.searchGptLastSearch;
    if (lastSearch && !hasPrevMessages) {
      this.send(lastSearch);
    }

    this.httpService.searchGptResponseChange.subscribe(x => {
      this.messages = [];
      this.linkButtons = [];
      this.botcount = 0;
      this.awaitingResponse = true;
      this.messages.push({ prompt: this.httpService.searchGptLastSearch, isUser: true, fillerMessage: true } as ChatGpMessage);


      this.handleResponse(x);
    })
  }

  startBotThinkingAnimation() {
    setInterval(() => {

      if (this.awaitingResponse)
        this.addBotThinkingMessage();

    }, 30000);
  }

  addBotThinkingMessage() {
    let message = '';

    if (this.botcount > 3) return;
    message = `<div class='dynamic'> ${this.botMessages[this.botcount]} </div>`
    this.botcount = this.botcount + 1;

    this.messages.push({ prompt: message, isUser: false, fillerMessage: true } as ChatGpMessage);
  }

  removeFillerMessages() {
    this.messages = this.messages.filter(x => {
      return x.fillerMessage != true || x.isUser;
    })
  }

  handleResponse(response: SeearchGptResponse) {
    this.removeFillerMessages();

    let message = '';
    const reportCount = 0;

    if (response.extracted_Reports) {
      const extractedReports = response.extracted_Reports;
      message = `<div class='dynamic'> ${this.intro} </div> <div class='gap'></div>`
      message = `${message} <div class='dynamic'> ${this.startText} </div> <div class='gap'></div>`

      const firstReport = extractedReports[0];
      let url = `${this.configService.configuration?.webUrl}/#/report-viewer/${firstReport.group_Id}/${firstReport.report_Id}`;
      message = `${message} <a href="${url}" (click)='alert('${reportCount}')> ${firstReport.name} </a><br> <div class='gap'></div>`
      message = `${message} <div class='dynamic'></div>`
      message = `${message} <div class='dynamic'> ${response.explanation} </div> <div class='gap'></div>`
      message = `${message} <div class='dynamic'> Other helpful reports are: </div> `
      const otherReports = extractedReports.slice(1);

      message = `${message} <ul  style="margin-bottom: 0px;">`

      otherReports?.forEach(x => {
        if (x.report_Id) {
          url = `${this.configService.configuration?.webUrl}/#/report-viewer/${x.group_Id}/${x.report_Id}`;
          message = `${message} <li  style="margin-bottom: 0px;"> <a href="${url}"> ${x.name} </a></li>`
        } else {

          const linkButton = { url: x.link, text: x.name } as LinkGpButton;
          this.linkButtons.push(linkButton);
        }
      });
      message = `${message} </ul>`
    }
    this.messages.push({ prompt: message, isUser: false, fillerMessage: false } as ChatGpMessage);
    this.awaitingResponse = false;

    this.searchGptServiceService.prevMessages = this.messages;
    this.searchGptServiceService.prevLinkButtons = this.linkButtons;
  }

  compare(a: ExtractedReport, b: ExtractedReport) {

    if ((a.rank as string) < (b.rank as string)) {
      return -1;
    }
    if ((a.rank as string) > (b.rank as string)) {
      return 1;
    }
    return 0;
  }

  test(url: string) {
    this.urlViewrService.setLatestUrl(url);
    this._router.navigate(['url-viewer']).then(() => {
      window.location.reload();
    });
  }

  send(messageText?: string | null) {
    if (!messageText)
      return;

    const typedMessage = messageText;

    this.messages = [];
    this.linkButtons = [];
    this.botcount = 0;
    this.awaitingResponse = true;
    this.form.controls.searchBoxMessage.setValue('');
    this.messages.push({ prompt: typedMessage, isUser: true, fillerMessage: true } as ChatGpMessage);
    const request: SeearchGptRequest = { prompt: typedMessage };
    this.httpService.searchGpt(request);
    this.addBotThinkingMessage();
  }
  openDisclosure() {
    this._router.navigate(['disclosure']).then(() => {
      window.location.reload();
    });
  }

  openGptHelp() {
    this._router.navigate(['gpthelp']).then(() => {
      window.location.reload();
    });
  }
}
