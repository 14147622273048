import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppConfig } from 'src/app/models/app.config';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClient } from '@angular/common/http';
import { MegamenuService } from 'src/app/services/megamenu.service';
import { SubjectArea } from 'src/app/models/subject-area';
import { DriveReport } from 'src/app/models/drive-report';
import { FormGroup, FormControl } from '@angular/forms';
import { UrlViewerServiceService } from '../url-viewer/url-viewer-service.service';
import { HttpService } from 'src/app/services/http.service';
import { SeearchGptRequest } from 'src/app/models/Requests/SearchGptRequest';
import { UserService } from 'src/app/services/user.service';
import { UserAccountService } from 'src/app/services/user-account.service';
import { WindowServiceService } from 'src/app/services/window-service.service';


@Component({
  selector: 'app-drive-header',
  templateUrl: './drive-header.component.html',
  styleUrls: ['./drive-header.component.scss']
})
export class DriveHeaderComponent implements OnInit, OnDestroy {
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  filteredDriveReportsSearchList$: DriveReport[] = [];

  driveReportsSearchList: DriveReport[] = [];
  isAdmin$ = false;
  favoriteReports: DriveReport[] = [];
  reportVisits: DriveReport[] = [];

  searchForm = new FormGroup({
    reportName: new FormControl('')
  });
  mobileHeaderVisible: boolean = false;

  constructor(public httpService: HttpService, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private broadcastService: MsalBroadcastService,
    private authService: MsalService, private configService: ConfigService, private http: HttpClient, public megamenuService: MegamenuService, private userAccountService: UserAccountService, private _router: Router, private urlViewrService: UrlViewerServiceService, private router: Router, public userService: UserService, public windowsService: WindowServiceService, private cdr: ChangeDetectorRef) { }

  // public isMobileLayout = false;
  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.getAccessTokenAndCallGraphAPI();
        this.getFavoriteReports();
        this.getReportVisits();
        this.megamenuService.isAdminSubject.subscribe(x => this.isAdmin$ = x);
      });

    this.megamenuService.userLatestSubjectAreasChange.subscribe(data => {
      const reportsFlatList = this.subjectAreasToListOfReports(data);
      this.driveReportsSearchList = reportsFlatList;
      this.filteredDriveReportsSearchList$ = this.driveReportsSearchList;
      this.cdr.detectChanges();
    });

    this.searchForm.controls.reportName.valueChanges.subscribe(value => {
      this.filteredDriveReportsSearchList$ = this._filter(value || '');
    });

  }

  getFavoriteReports() {
    this.userService.getFavoriteReportsForUser().subscribe(x => {
      this.favoriteReports = x.reverse();
    });
  }

  getReportVisits() {
    this.userService.getReportVisitsForUser().subscribe(x => {
      this.reportVisits = x;
    });
  }

  shrinkAccountName() {
    if (this.account?.name && this.account?.name.includes(' ')) {
      return this.account?.name.split(' ')[0];
    }
    return this.account?.name;

  }

  searchGpt() {
    const searchValue = this.searchForm.controls.reportName.value;
    const request: SeearchGptRequest = { prompt: searchValue };

    const route = this.router.url;
    if (route.includes('search-gpt'))
      this.httpService.searchGpt(request);
    this.httpService.searchGptLastSearch = request?.prompt as string;

    this.searchForm.controls.reportName.setValue('');
    this.router.navigate(['search-gpt']);
  }

  navigateToReportViewOld(report: DriveReport) {
    if (report.workSpaceId && report.powerBiReportId) {
      this.router.navigate(['report-viewer', report.workSpaceId, report.powerBiReportId]).then(() => {
        window.location.reload();
      });
    } else {
      this.urlViewrService.setLatestUrl(report.url);
      this.router.navigate(['url-viewer']).then(() => {
        window.location.reload();
      });
    }
  }

  navigateToReportView(report: DriveReport) {
    if (report.workSpaceId && report.powerBiReportId) {
      this._router.navigate(['report-viewer', report.workSpaceId, report.powerBiReportId, report.id]).then(() => {
        window.location.reload();
      });
    } else {
      this.urlViewrService.setLatestUrl(report.url);
      this._router.navigate(['url-viewer']).then(() => {
        window.location.reload();
      });
    }
  }

  goToLink(report: DriveReport) {
    window.open(report.url, "_blank");
  }

  displayFn(options: DriveReport[] | null) {
    return (id: string) => {
      const correspondingOption = Array.isArray(options) ? options.find(option => option.id === id) : null;
      return correspondingOption ? correspondingOption.name : '';
    }
  }

  private _filter(value: string): DriveReport[] {
    const filterValue = value.toLowerCase();
    const nameMatch = this.driveReportsSearchList.filter(option => option?.name.toLowerCase().includes(filterValue));
    const descriptionMatch = this.driveReportsSearchList.filter(option => {
      if (option?.description)
        return option.description.toLowerCase().includes(filterValue);
      return false;
    });

    const tagsMatch = this.driveReportsSearchList.filter(option => {
      if (option?.tags)
        return option.tags.toLowerCase().includes(filterValue);
      return false;
    });

    const filtered = [...nameMatch, ...descriptionMatch, ...tagsMatch];
    const unique = [...new Map(filtered.map(v => [v.id, v])).values()]
    return unique;
    // return this.driveReportsSearchList.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  subjectAreasToListOfReports(subjectAreas: SubjectArea[]) {
    let driveReportsFlatList: DriveReport[] = [];

    subjectAreas.forEach((subjectArea) => {

      subjectArea.categories.forEach(category => {
        driveReportsFlatList = driveReportsFlatList.concat(category.driveReports);

        category.subCategories.forEach(subCategory => {
          driveReportsFlatList = driveReportsFlatList.concat(subCategory.driveReports);

          subCategory.subCategoryTwos.forEach(subCategoryTwo => {
            driveReportsFlatList = driveReportsFlatList.concat(subCategoryTwo.driveReports);
          });
        });

      });
    });

    return driveReportsFlatList;
  }


  account?: AccountInfo;

  setLoginDisplay() {
    const accounts = this.authService.instance.getAllAccounts();
    if (accounts) {
      this.loginDisplay = accounts.length > 0;
      this.account = accounts[0];
      this.userService.userId = this.account.localAccountId;
      this.userAccountService.account = this.account;
      return;
    }

    this.loginDisplay = false;

  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }


  logout() { // Add log out function here
    const config = this.configService.readConfig() as AppConfig;
    const webUrl = `${config.webUrl}`;
    this.authService.logoutRedirect({
      postLogoutRedirectUri: webUrl
    });
  }

  getAccessTokenAndCallGraphAPI() {
    this.authService.acquireTokenSilent({
      scopes: ['group.Read.All'], account: this.account
    }).subscribe(result => {

      this.megamenuService.getAccessTokenAndCallGraphAPI(result.accessToken, this.account?.username as string);
    })
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  // isAdmin():Observable<bool> {
  //  return this.megamenuService.isAdmin();
  // }

  deletefavoriteReport(report: DriveReport) {
    this.userService.DeleteReportFavorites(report.id).subscribe(() => {
      this.userService.removeFromFavoritesListLocal(report.id);
    })
  }

  deleteReportVisits() {
    this.userService.DeleteReportVisits().subscribe(() => {
      this.userService.removeFromVisitListLocal();
    })
  }


  isVisible(report: DriveReport) {
    return report.isVisibleInFavs;
  }

  setReportVisible(index: number) {
    this.userService.favoriteReports[index].isVisibleInFavs = true;

  }

  setReportNotVisible(index: number) {
    this.userService.favoriteReports[index].isVisibleInFavs = false;
  }

  navigateToManageFavorites() {
    this.router.navigate(['favorite-manager']);
  }

  setHeaderHoveredMegamenu() {
    this.megamenuService.headerHoveredLast = true;
  }

  setHeaderExitedMegamenu() {
    this.megamenuService.headerHoveredLast = false;
  }

  openedFavMenu() {
    // this.megamenuService.isInteractable = false;
    this.megamenuService.headerHoveredLast = true;
  }


  closedFavMenu() {
    this.megamenuService.headerHoveredLast = false;
  }

  toggleMobileHeader() {
    this.megamenuService.mobileHeaderVisible = !this.megamenuService.mobileHeaderVisible
  }
}





