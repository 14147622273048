import { Component, OnInit } from '@angular/core';
import { ConfigService } from './services/config.service';
import { AppConfig } from './models/app.config';

export interface ConfigResponse {
  embedUrl: string;
  embedToken: string;
  reportId: string;
  isUserAuth:boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'msal-angular-tutorial';
  config!: AppConfig;
  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
  }
}
