<div class="modal__content">
  <p class="message">{{ promptMessage }}</p>
</div>

<div class="modal__footer">
  <button mat-stroked-button color="warn" class="nl-btn" type="button" (click)="onYes()">
    {{ yesBtnLabel }}
  </button>

  <button mat-stroked-button color="primary" class="nl-btn nl-btn--square" type="button" (click)="onNo()">
    {{ noBtnLabel }}
  </button>
</div>
