import { Injectable } from '@angular/core';
import { AppConfig } from '../models/app.config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { FeaturedReport, UpdateAdGroupFeaturedReportsRequest } from '../models/featuredreports';

@Injectable({
  providedIn: 'root'
})
export class FeaturedReportsServiceService {

  baseUrl: string;
  config!: AppConfig;
  constructor(private httpClient: HttpClient, private configService: ConfigService, private router: Router) {
    //debugger;
    this.config = this.configService.readConfig() as AppConfig;
    this.baseUrl = `${this.config.apiUrl}/api`;


  }


  updateFeaturedReportsbyAdGroupId(adGroupId: string, request: UpdateAdGroupFeaturedReportsRequest): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/adgroup/${adGroupId}/featured-reports`;
    return this.httpClient.post<boolean>(requestUrl, request);
  }

  getFeaturedReportsForAdGroup(adGroupId: string): Observable<FeaturedReport[]> {
    const requestUrl = `${this.baseUrl}/adgroup/${adGroupId}/featured-reports`;
    return this.httpClient.get<FeaturedReport[]>(requestUrl);
  }
}
