import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, startWith, map } from 'rxjs';

@Component({
  selector: 'app-autocomplete-save',
  templateUrl: './autocomplete-save.component.html',
  styleUrls: ['./autocomplete-save.component.scss']
})
export class AutocompleteSaveComponent implements OnChanges {
  @Input() myControl = new FormControl('');
  @Input() labelName: string = '';
  @Input() required: boolean = false;

  @Input() disabled: boolean = false;

  @Output() latestText = new EventEmitter<string>();
  @Output() addNewEntry = new EventEmitter<string>();
  filteredOptions!: Observable<any[]>;

  private _options: any[] = [];
  @Input()
  set options(value: any[]) {
    if (!value) return;

    this._options = value;

    if (this._options.some(x => x.isNew)) {
      const newOption = this._options.find(x => x.isNew);

    }

    const t = this.myControl.value;
    this.myControl.setValue(t);
    this.myControl.markAsDirty();
  }

  get options(): any[] {
    return this._options;
  }

  latestTextTemp = '';
  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );


  }

  onChangeEvent($event: any) {
    if ($event) {
      this.latestTextTemp = this.myControl.value as string;

      this.latestText.emit(this.latestTextTemp);

    }
  }

  onSelectionChange($event: MatAutocompleteSelectedEvent) {
    this.latestTextTemp = $event.option.viewValue;
    this.latestText.emit($event.option.viewValue);

  }

  isNewEntry(): boolean {

    return this.latestTextTemp.length > 2 && !this.options.some(e => e.name === this.latestTextTemp)
  }

  addNew() {
    if (this.myControl.value) {
      this.addNewEntry.emit(this.myControl.value);
    }

  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  displayFn(value: any) {
    return this.options.find((option) => option.id === value)?.name;
  }
}
