import { Component, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AddReportModalComponent } from '../add-report-modal/add-report-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from 'src/app/services/http.service';
import { DriveReport } from 'src/app/models/drive-report';
import { DriveReportResponse } from 'src/app/models/drive-report-response';
import { ResponseModalComponent } from '../response-modal/response-modal.component';
import { UserService } from 'src/app/services/user.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-report-manager',
  templateUrl: './report-manager.component.html',
  styleUrls: ['./report-manager.component.scss']
})
export class ReportManagerComponent {
  driveReports: DriveReportResponse[] = [];

  dragDisabled = true;
  displayedColumns: string[] = ['name', 'description', 'createdDate', 'actions'];
  headerFont = '18px';
  headerColor = '#00263E';
  tableHeaderColor = '#E2EEF8';
  dataSource = [] as DriveReport[];

  selectedRowIndex = 0;

  constructor(public httpService: HttpService, private _sanitizer: DomSanitizer, public dialog: MatDialog, public _userService: UserService) { }

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngOnInit() {
    this._userService.getFavoriteReportsForUser().subscribe(() => {
      this.dataSource = this._userService.favoriteReports;
    })

  }
  ngAfterViewInit() {
  }

  openAddReportModal(index: number | null) {
    const editMode = index == null ? false : true;

    const dialogRef = this.dialog.open(AddReportModalComponent, {
      maxWidth: '60vw',
      maxHeight: '100vh',
      height: '90%',
      width: '90%',
      panelClass: 'full-screen-modal',
      data: editMode ? this.driveReports[index!] : null
    });

    const dialogRefInstance = dialogRef.componentInstance;
    dialogRefInstance.editMode = editMode;

    dialogRef.componentInstance.saveSuccess.subscribe(() => {
      const successDialogRef = this.dialog.open(ResponseModalComponent, {

        height: '30%',
        width: '30%',
        panelClass: 'full-screen-modal'
      });


      const instance = successDialogRef.componentInstance;
      instance.success = true;
      instance.message = `Report Successfully ${editMode ? 'Edited' : 'Added'}`;
      dialogRef.close();
      setTimeout(() => { successDialogRef.close() }, 3000);

      successDialogRef.afterClosed().subscribe(() => {
      });

    });
  }

  deleteReport(reportId: string) {

    this._userService.DeleteReportFavorites(reportId).subscribe(() => {
      this._userService.removeFromFavoritesListLocal(reportId);
      this.showResponseModal(true, 'Favorite Successfully Deleted');
    })

  }

  showResponseModal(success: boolean, message: string) {
    const successDialogRef = this.dialog.open(ResponseModalComponent, {

      height: '30%',
      width: '30%',
      panelClass: 'full-screen-modal'
    });

    // this.getReports();

    const instance = successDialogRef.componentInstance;
    instance.success = success;
    instance.message = message;
    setTimeout(() => { successDialogRef.close() }, 3000);

    successDialogRef.afterClosed().subscribe(() => {
    });
  }


  @ViewChild('table', { static: true })
  table!: MatTable<DriveReport>;


  drop(event: CdkDragDrop<any>) {
    // Return the drag container to disabled.
    this.dragDisabled = true;

    const previousIndex = this.dataSource.findIndex((d) => d === event.item.data);

    moveItemInArray(this.dataSource, previousIndex, event.currentIndex);
    this.table.renderRows();
  }
}




