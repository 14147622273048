import { Component } from '@angular/core';
import { UrlViewerServiceService } from './url-viewer-service.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-url-viewer',
  templateUrl: './url-viewer.component.html',
  styleUrls: ['./url-viewer.component.scss']
})
export class UrlViewerComponent {

  public safeUrl!: SafeResourceUrl;
  constructor(private urlViewrService: UrlViewerServiceService, private _sanitizer: DomSanitizer, private userService: UserService) {

    setTimeout(() => {
      const url = this.urlViewrService.getLatestUrl() as string;
      const reportId = this.urlViewrService.getLatestUrlAssociatedReportId() as string;
      this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(url);
      this.userService.AddUrlVisit(url, reportId).subscribe(x => {
        this.userService.addToVisitListLocal(x);
      });
    }, 2000);
  }

  ngOnInit() {

  }

}
