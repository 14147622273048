import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Category } from 'src/app/models/category';
import { DriveReport } from 'src/app/models/drive-report';
import { SubCategory } from 'src/app/models/sub-category';
import { SubCategoryTwo } from 'src/app/models/sub-categorytwo';
import { SubjectArea } from 'src/app/models/subject-area';
import { HttpService } from 'src/app/services/http.service';
import { MegamenuService } from 'src/app/services/megamenu.service';
import { UrlViewerServiceService } from '../url-viewer/url-viewer-service.service';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-drive-megamenu',
  templateUrl: './drive-megamenu.component.html',
  styleUrls: ['./drive-megamenu.component.scss']
})
export class DriveMegamenuComponent implements OnInit, OnDestroy {
  @Input() show: boolean = false;
  showList: boolean = false;
  subjectAreas: SubjectArea[] = [];
  selectedSubjectArea: SubjectArea | null = null;
  selectedSubjectAreaIndex = -1;
  selectedCategory: Category | null = null;
  currentViewedReportId = '';
  selectedSubCategory: SubCategory | null = null;
  selectedSubCategoryTwo: SubCategoryTwo | null = null;

  userHasAcceess = true;
  userNotInCorrectAdGroup = 'Gallagher Drive is a permissions-based. For more information, including how to request permission in IDM, visit the help page. '

  subscriptions: Subscription[] = [];

  constructor(public httpService: HttpService, private authService: MsalService, public megamenuService: MegamenuService, private _router: Router, private _sanitizer: DomSanitizer, private urlViewrService: UrlViewerServiceService, public userService: UserService) { }

  ngOnInit() {
    // this.setMegamenuSubscriptions();
    this.checkStorageForData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    })
  }

  hideList() {
    this.showList = false;
  }

  checkStorageForData() {
    const lastCacheDate = localStorage.getItem("MEGAMENU_SUBJECTAREAS_BYNAME_LAST_CACHE_DATE");
    if (lastCacheDate) {
      const elapsedMinutes = (new Date().getTime() - new Date(lastCacheDate).getTime()) / 60000;

      if (elapsedMinutes > 60) {
        this.setMegamenuSubscriptions();
        return;
      }

      const subjectAreasString = localStorage.getItem("MEGAMENU_SUBJECTAREAS_BYNAME_VALUE");
      const adlist = localStorage.getItem("USERS_AZURE_ADGROUPS") as string;
      this.megamenuService.userAzureAdGroups = JSON.parse(adlist) as string[];

      if (subjectAreasString) {
        this.httpService.megamenuLoading = false;
        this.subjectAreas = this.sortList(JSON.parse(subjectAreasString) as SubjectArea[]);
        this.subjectAreas.forEach(subjectArea => {
          subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + subjectArea.image);
        });
        this.megamenuService.userLatestSubjectAreasChange.next(this.subjectAreas);
        this.megamenuService.isAdminCheck();
        this.selectedSubjectArea = this.subjectAreas[0];
      }
      return;
    }
    this.setMegamenuSubscriptions();
  }

  setMegamenuSubscriptions() {
    // TODO: Put all megamenu service work in  the mega menu service. Some functions are still in the general https services from early design.
    try {
      const subscription1 = this.httpService.megaMenuSubjectAreasChange.subscribe(data => {

        this.subjectAreas = this.sortList(data);

        this.subjectAreas.forEach((subjectArea) => {
          subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'
            + subjectArea.image);
        });

        if (this.subjectAreas) {
          this.selectedSubjectArea = this.subjectAreas[0];
        }

      });

      const subscription2 = this.megamenuService.userAssignedAdGroupsChange.subscribe(adGroupsList => {
        const adGroupsStringList: string[] = adGroupsList.map(x => x.displayName);

        const subscription3 = this.megamenuService.getMegaMenuSubjectAreaForAdGroupNames(adGroupsStringList).subscribe(data => {
          this.subjectAreas = this.sortList(data);

          this.subjectAreas.forEach((subjectArea) => {
            subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'
              + subjectArea.image);
          });

          if (this.subjectAreas) {
            this.selectedSubjectArea = this.subjectAreas[0];
          }

          this.httpService.megamenuLoading = false;

        },
          err => {
            this.userHasAcceess = false;
            this.httpService.megamenuLoading = false;
            console.log('HTTP Error', err)
          })

        this.subscriptions.push(subscription3);

      });

      this.subscriptions.push(subscription1);
      this.subscriptions.push(subscription2);
    } catch (error: any) {
      // Prepare status message for Embed failure

      const displayMessage = `Failed to fetch config for report. Status: ${error.statusText} Status Code: ${error.status}`;
      console.error(displayMessage);
      return;
    }
  }

  sortList(subjectAreas: SubjectArea[]): SubjectArea[] {
    const subjectAreasSorted = subjectAreas.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1);
    return subjectAreasSorted;
  }

  getAccessTokenAndCallGraphAPI() {
    const accounts = this.authService.instance.getAllAccounts();
    const account = accounts[0];

    this.authService.acquireTokenSilent({
      scopes: ['group.Read.All'], account: account
    }).subscribe(result => {

      this.megamenuService.getAccessTokenAndCallGraphAPI(result.accessToken, account.username);
    })
  }

  setReportId(reportId: string) {
    this.currentViewedReportId = reportId;
  }

  subjectAreaSelected(selectedsubjectArea: SubjectArea, index: number) {

    if (this.megamenuService.isInteractable) {
      this.selectedSubjectArea = selectedsubjectArea;
      this.selectedCategory = null;
      this.selectedSubCategory = null;
      this.selectedSubCategoryTwo = null;

      this.selectedSubjectAreaIndex = index;
      this.showList = true;
    }

  }

  categorySelected(selectedCategory: Category) {
    this.selectedCategory = selectedCategory;
    this.selectedSubCategory = null;
    this.selectedSubCategoryTwo = null;
  }
  removeCategorySelected() {
    this.selectedCategory = null;
    this.selectedSubCategory = null;
  }


  subCategorySelected(selectedSubCategory: SubCategory) {
    this.selectedSubCategory = selectedSubCategory;
    this.selectedSubCategoryTwo = null;
  }

  removesubCategorySelected() {
    this.selectedSubCategory = null;
    this.selectedSubCategoryTwo = null;
  }

  subCategoryTwoSelected(selectedSubCategoryTwo: SubCategoryTwo) {
    this.selectedSubCategoryTwo = selectedSubCategoryTwo;
  }
  removesubCategoryTwoSelected() {
    this.selectedSubCategoryTwo = null;
  }

  navigateToReportViewold(workSpaceId: string, powerBiReportId: string) {
    this._router.navigate(['report-viewer', workSpaceId, powerBiReportId]).then(() => {
      window.location.reload();
    });
  }

  navigateToReportView(report: DriveReport) {
    if (report.workSpaceId && report.powerBiReportId) {
      this._router.navigate(['report-viewer', report.workSpaceId, report.powerBiReportId, report.id]).then(() => {
        window.location.reload();
      });
    } else {
      this.urlViewrService.setLatestUrl(report.url);
      this._router.navigate(['url-viewer']).then(() => {
        window.location.reload();
      });
    }
  }

  goToLink(report: DriveReport) {
    this.userService.AddUrlVisit(report.url, report.id).subscribe(x => {
      this.userService.addToVisitListLocal(x);
    });
    window.open(report.url, "_blank");
  }

  enteredArea() {
    this.megamenuService.headerHoveredLast = false;
  }
}
