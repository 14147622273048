<div *ngIf="!loginDisplay">
  <p>Please sign-in to Drive.</p>
</div>

<div *ngIf="loginDisplay">
  <div id="DriveUXbannermain" style="position: relative">
    <div id="bannerimage" style="">
      <div class="DriveUXbannermaindivwithpara">
        <div>
          <h1 class="DriveUXbannermainHeading">Welcome to Gallagher Drive</h1>
          <h4 class="DriveUXbannermainSubHeading">Powerful Insights That Drive Action To Face Your Future With Confidence.</h4>
        </div>
      </div>
    </div>
  </div>
  <div id="DriveUXbanner">
    <div id="DriveUXbannerheadingmain" class="uni-pad">
      <div id="DriveUXbannerheading">
        <p>
          Gallagher Drive is our premier data and analytics platform that transforms raw data into powerful insights for you and your clients. These tools create data visualizations that help provide your clients confidence in their risk management
          program by using data and analytics to show they have the right coverages, are placed with the right carrier, and are getting the right price. Our Gallagher Drive analytics consultants provide industry-leading consulting expertise by
          leveraging our rich in-house data sets to help your clients get the most out of their risk management programs and lower their cost of risk.
        </p>
      </div>
    </div>
  </div>
  <app-drive-features-viewer [featuredReportList]="driveFeatures" [headerName]="'Drive Features'"> </app-drive-features-viewer>

  <app-drive-features-viewer [featuredReportList]="featuredReports" [headerName]="'Featured Reports'"> </app-drive-features-viewer>

  <app-tool-calculators-static-viewer></app-tool-calculators-static-viewer>

  <app-drive-features-viewer [featuredReportList]="newsAndInsights" [headerName]="'News & Insights'"> </app-drive-features-viewer>
  <footer>
    <div id="footersocialmedia" class="uni-pad">
      <div id="DriveUXsitemapmain">
        <div id="DriveUXsitemap" class="footerlinksection">
          <div style="margin-left: 10%">
            <img src="../../../assets/images/FooterLogo2.png" alt="" />
          </div>

          <div id="DriveUXsitemapIndustries">
            <p class="Industries"><u>US Resources</u></p>

            <a
              style="cursor: pointer"
              target="_blank"
              href="https://teams.microsoft.com/l/team/19%3aeFpJ2xNGPlXo2vUHbAVurkuBnU0v0AAiPPCUnd4p5do1%40thread.tacv2/conversations?groupId=3734acb6-368c-47bb-9e6e-d086fd3ea8c6&tenantId=6cacd170-f897-4b19-ac58-46a23307b80a"
            >
              <p>Teams Support</p>
            </a>

            <a style="cursor: pointer" target="_blank" href="https://ajg0.sharepoint.com/sites/GGBUS-Gdrive/SitePages/Getting-Started-and-Support.aspx">
              <p>Drive Support SharePoint</p>
            </a>
            <a style="cursor: pointer" target="_blank" href="https://ajg0.sharepoint.com/sites/GGBUS-Gdrive/SitePages/home.aspx">
              <p>Drive Home SharePoint</p>
            </a>
            <a style="cursor: pointer" target="_blank" href="https://ajg0.sharepoint.com/sites/GGBUS-Gdrive/SitePages/Training-+-Resources.aspx#userguides">
              <p>User Guides</p>
            </a>
            <a
              style="cursor: pointer"
              target="_blank"
              href="https://ajg0.sharepoint.com/sites/GGBUS-Gdrive/Gallagher%20Drive%20%20User%20Guides/Forms/AllItems.aspx?id=%2fsites%2fGGBUS-Gdrive%2fGallagher%20Drive%20%20User%20Guides%2f_Request%20Access%20to%20Gallagher%20Drive_User%20Guide.pdf&parent=%2fsites%2fGGBUS-Gdrive%2fGallagher%20Drive%20%20User%20Guides"
            >
              <p>IDM Guide</p>
            </a>
          </div>

          <div id="DriveUXsitemapInsurance">
          <br/>
            <a style="cursor: pointer" target="_blank" href="https://ajg0.sharepoint.com/sites/GGBUS-sales/SitePages/BOR-Support.aspx">
              <p>BOR Support</p>
            </a>
            <a style="cursor: pointer" target="_blank" href="https://ajg0.sharepoint.com/sites/GGBAmericas-Core360">
              <p>CORE360</p>
            </a>
            <a style="cursor: pointer" target="_blank" href="https://ajg0.sharepoint.com/sites/GGBAmericas-Core360/SitePages/Client-Situations-Tool-Guide.aspx">
              <p>Risk Profile Presentation</p>
            </a>
            <a style="cursor: pointer" target="_blank" href="https://gallagher.my.salesforce.com">
              <p>WIN</p>
            </a>
            <a style="cursor: pointer" (click)="navigateToReportView('42cb151b-4586-418f-b0e8-61326c2ed4ba', 'cd209299-4809-42f4-b2c6-0e84d3c5eb38', '3d228932-3a6f-4803-992d-b609d7ed5d3f')">
              <p>Connect Partner Finder</p>
            </a>
          </div>
        </div>
        <div id="DriveUXsitemapline"></div>
      </div>
      <div id="DriveUXfootersocialmediamain">
        <div id="DriveUXfootersocialmedia">
          <a target="_blank" href="https://www.linkedin.com/company/gallagher-global/"><img src="../../../assets/images/linkedin.svg" alt="" /></a>
          <a target="_blank" href="https://www.twitter.com/GallagherGlobal"><img src="../../../assets/images/footer-social-icon-X.svg" alt="" /></a>
          <a target="_blank" href="https://www.youtube.com/GallagherGlobal"><img src="../../../assets/images/youtube.svg" alt="" /></a>
          <a target="_blank" href="https://www.instagram.com/gallagherglobal/"><img src="../../../assets/images/instagram.svg" alt="" /></a>
          <a target="_blank" href="https://www.facebook.com/GallagherGlobal/"><img src="../../../assets/images/facebook.svg" alt="" /></a>
        </div>
        <div id="DriveUXfooterbacktotop">
          <a href=""><span class="DriveUXfooterbacktotoptext">TOP</span><img src="../../../assets/images/backtotopicon.svg" alt="" /></a>
        </div>
      </div>
    </div>
    <nav>
      <div id="DriveUXfooternavmain" class="uni-pad">
        <div id="DriveUXfooternav">
          <p><a href="">Contact Us</a></p>
          <p><a href="">Terms of Use</a></p>
          <p><a href="">Privacy Policy</a></p>
        </div>
        <div id="DriveUXfootercopyright">
          <p>&copy; 2023 Arthur J. Gallagher & Co.</p>
        </div>
      </div>
    </nav>
  </footer>
</div>
