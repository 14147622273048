import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from 'src/app/services/http.service';
import { SubjectArea } from 'src/app/models/subject-area';
import { Category } from 'src/app/models/category';
import { DriveReport } from 'src/app/models/drive-report';
import { SubCategory } from 'src/app/models/sub-category';
import { BiGroupDto, BiReportDto } from 'src/app/models/bi';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Country } from 'src/app/models/country';

enum modes {
  subjectArea,
  category,
  subCategory,
  report
}

enum snackbarModes {
  success,
  error
}

@Component({
  selector: 'app-report-manager-modal',
  templateUrl: './report-manager-modal.component.html',
  styleUrls: ['./report-manager-modal.component.scss']
})

export class ReportManagerModalComponent {
  tempName = '';
  tempDescription = '';
  tempReportId = '';
  tempWorkSpaceId = '';
  tempImageBase64 = '';
  tempCountries: string[] = [];

  viewModes = modes;
  snackbarModes = snackbarModes;
  selectedMode = modes.subjectArea;
  subjectAreas: SubjectArea[] = [];
  categories: Category[] = [];
  subCategories: SubCategory[] = [];
  reports: DriveReport[] = []
  selectedSubjectArea: SubjectArea | null = null;
  selectedCategory: Category | null = null;
  selectedSubCategory: SubCategory | null = null;
  selectedReport: DriveReport | null = null;

  issubCategoryLastSelected  = false; //used for report save.

  this: any;

  groupIdDropdownValues: BiGroupDto[] = [];
  reportIdDropdownValues: BiReportDto[] = [];
  countries: string[] = [];

 constructor(public httpService: HttpService, private _sanitizer: DomSanitizer, private _snackBar: MatSnackBar) {}

 ngOnInit(){
  try {
    //get values here
    this.httpService.getSubjectAreas('').subscribe(data => {
      this.subjectAreas = data as SubjectArea[];

      this.subjectAreas.forEach((subjectArea) => {
       subjectArea.safeResourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'
       + subjectArea.image);

       subjectArea.isNew = false;
      });

      if(this.subjectAreas) {
        this.subjectAreaSelected(this.subjectAreas[0]);
      }

    });
  } catch (error: any) {
    // Prepare status message for Embed failure
    const displayMessage = `Failed to fetch config for report. Status: ${error.statusText} Status Code: ${error.status}`;
    console.error(displayMessage);
    return;
  }

  this.httpService.getBiGroups().subscribe(data => {
    this.groupIdDropdownValues = data as BiGroupDto[];

  });

  this.httpService.getCountries().subscribe(data => {

    const temp = data as Country[];

    this.countries = temp.map(function(country) {
      return country['name'];
    });


  });
}

selectectCountriesUpdated(countries: string[]) {
this.tempCountries = countries;
}

updateImageBase64String(base64String: any){
this.tempImageBase64 = base64String;
}

groupDropdownChanged(v: any) {
  this.getReportsForWorkSpace(v.value);

}

getReportsForWorkSpace(workspaceId: string) {
  this.httpService.getBiReports(workspaceId).subscribe(data => {
    this.reportIdDropdownValues = data as BiReportDto[];
  });
}

setFormFields(name: string, description: string, reportId: string = '', workSpaceId: string = '', base64ImageString = '', tempCountries: Country[] = []) {
 this.tempName = name;
 this.tempDescription = description;
 this.tempReportId = reportId;
 this.tempWorkSpaceId = workSpaceId;
 this.tempImageBase64 = base64ImageString;
 this.tempCountries = tempCountries.map(country => {return country.name});
}

getSubjectAreas() {
 return this.subjectAreas.filter(sa => {
  return !sa.isNew
});
}

 addSubjectAreaView() {
  this.selectedMode = this.viewModes.subjectArea;
  const newSubjectArea = {
    name:'',
    description:'',
    isNew: true} as SubjectArea;

  this.subjectAreaSelected(newSubjectArea);
 }

 addCategoryView() {
  this.selectedMode = this.viewModes.category;
  const newCategory = {
    name:'',
    description:'',
    isNew: true} as Category;

    this.categorySelected(newCategory);
 }

 addSubCategoryView() {
  this.selectedMode = this.viewModes.subCategory;
  const newSubCategory = {
    name:'',
    description:'',
    isNew: true} as SubCategory;

    this.subCategorySelected(newSubCategory);
 }

 addReportView() {
  const newReport = {
    name:'',
    description:'',
    id: '',
    groupId: '',
    sequence: 0,
    isNew: true} as DriveReport;

  if(this.selectedMode == this.viewModes.category) {
    newReport.categoryId = this.selectedCategory?.id ? this.selectedCategory?.id : '';
  } else if (this.selectedMode == this.viewModes.subCategory) {
    newReport.subCategoryId = this.selectedSubCategory?.id ? this.selectedSubCategory?.id : '';
  }

  this.selectedMode = this.viewModes.report;
    this.reportSelected(newReport);
 }

 subjectAreaSelected(subjectArea: SubjectArea){
  this.selectedMode = this.viewModes.subjectArea;
  this.selectedSubjectArea = subjectArea;
  this.categories = this.selectedSubjectArea.categories
  this.subCategories = [];
  this.reports = [];

  this.setFormFields(this.selectedSubjectArea.name, this.selectedSubjectArea.description,'','', this.selectedSubjectArea.image, this.selectedSubjectArea.countries)

 }

 categorySelected(category: Category) {
  this.selectedMode = this.viewModes.category;
  this.selectedCategory = category;
  this.subCategories = this.selectedCategory.subCategories;
  this.reports = this.selectedCategory.driveReports;

  this.issubCategoryLastSelected = false;
  this.setFormFields(this.selectedCategory.name, this.selectedCategory.description,'','', this.selectedCategory.image, [] as Country[])
 }

 subCategorySelected(subCategory: SubCategory) {
  this.selectedMode = this.viewModes.subCategory;
  this.selectedSubCategory = subCategory;
  this.reports = this.selectedSubCategory.driveReports;

  this.issubCategoryLastSelected = true;
  this.setFormFields(this.selectedSubCategory.name, this.selectedSubCategory.description,'','', this.selectedSubCategory.image, [])
 }

 reportSelected(report: DriveReport) {
  this.selectedMode = this.viewModes.report;
  this.selectedReport = report;

  this.getReportsForWorkSpace(this.selectedReport.workSpaceId);
  this.setFormFields(this.selectedReport.name, this.selectedReport.description, this.selectedReport.id, this.selectedReport.workSpaceId, this.selectedReport.image, [])


  this.selectedMode = this.viewModes.report;
 }

 getCategoryCount(reportGroup: SubjectArea) {
  if(reportGroup.categories)
    return reportGroup.categories.length;
  else
  return 0;
 }

 getSubCategoryCount(category: Category) {
  if(category.subCategories)
    return category.subCategories.length;
  else
  return 0;
 }

 getReportCountForCategory(category: Category) {
  if(category.driveReports)
    return category.driveReports.length;

  else
    return 0;
 }

 getReportCountForSubCategory(subCategory: SubCategory ) {
  if (subCategory.driveReports)
  return subCategory.driveReports.length;
  else
    return 0;
 }

 saveSubjectArea(){
  if(this.selectedSubjectArea) {
    const alreadyExistsInDb = this.selectedSubjectArea.id ? true : false;

    this.selectedSubjectArea.name = this.tempName;
    this.selectedSubjectArea.description = this.tempDescription;
    this.selectedSubjectArea.image = this.tempImageBase64;
    this.selectedSubjectArea.countries = this.tempCountries.map(countryName => ({name: countryName} as Country));


    if(alreadyExistsInDb) {
      //update

      this.httpService.UpdateSubjectArea(this.selectedSubjectArea).subscribe(() => {
        if(this.selectedSubjectArea) {
          this.openSnackBar(`Success: Updated ${this.selectedSubjectArea.name}`, 'close', this.snackbarModes.success);
        }
        });
    } else {
      //save

      this.selectedSubjectArea.sequence = this.subjectAreas ? this.subjectAreas.length : 0;

      this.httpService.CreateSubjectArea(this.selectedSubjectArea).subscribe(Id => {
        if(this.selectedSubjectArea) {
          this.selectedSubjectArea.isNew = false;
          this.selectedSubjectArea.id = Id;

          if(this.subjectAreas)
            this.subjectAreas.push(this.selectedSubjectArea)
          else
            this.subjectAreas = [this.selectedSubjectArea];
            // console.log(Id);

          this.openSnackBar(`Success: Created ${this.selectedSubjectArea.name}`, 'close', this.snackbarModes.success);

        }

        });

    }



  }
 }

 saveCategory() {
  if(this.selectedCategory) {
    const alreadyExistsInDb = this.selectedCategory.id ? true : false;

    this.selectedCategory.name = this.tempName;
    this.selectedCategory.description = this.tempDescription;
    this.selectedCategory.image = this.tempImageBase64;
    if(alreadyExistsInDb) {
      //update

      this.httpService.UpdateCategory(this.selectedCategory).subscribe(success => {
        if(this.selectedCategory) {
          this.openSnackBar(`Success: Updated ${this.selectedCategory.name}`, 'close', this.snackbarModes.success);
        }
        });
    } else {
      //save

      this.selectedCategory.sequence = this.categories ? this.categories.length : 0;
      this.selectedCategory.subjectAreaId = this.selectedSubjectArea?.id as string;

      this.httpService.CreateCategory(this.selectedCategory).subscribe(Id => {
        if(this.selectedCategory) {
          this.selectedCategory.isNew = false;
          this.selectedCategory.id = Id;

          if(this.categories)
            this.categories.push(this.selectedCategory)
          else
            this.categories = [this.selectedCategory];


          this.openSnackBar(`Success: Created ${this.selectedCategory.name}`, 'close', this.snackbarModes.success);
        }

        });
    }




  }
 }

 saveSubCategory() {
  if(this.selectedSubCategory) {
    const alreadyExistsInDb = this.selectedSubCategory.id ? true : false;

    this.selectedSubCategory.name = this.tempName;
    this.selectedSubCategory.description = this.tempDescription;
    this.selectedSubCategory.image = this.tempImageBase64;
    if(alreadyExistsInDb) {

      this.httpService.UpdateSubCategory(this.selectedSubCategory).subscribe(success => {
        if(this.selectedSubCategory) {
          this.openSnackBar(`Success: Updated ${this.selectedSubCategory.name}`, 'close', this.snackbarModes.success);
        }

        });
    } else {
      //save

      this.selectedSubCategory.sequence = this.subCategories ? this.subCategories.length : 0;
      this.selectedSubCategory.categoryId = this.selectedCategory?.id as string;

      this.httpService.CreateSubCategory(this.selectedSubCategory).subscribe(Id => {
        if(this.selectedSubCategory) {
          this.selectedSubCategory.isNew = false;
          this.selectedSubCategory.id = Id;

          if(this.subCategories)
            this.subCategories.push(this.selectedSubCategory)
          else
            this.subCategories = [this.selectedSubCategory];

          this.openSnackBar(`Success: Created ${this.selectedSubCategory.name}`, 'close', this.snackbarModes.success);
        }

        });
    }


  }
 }

 saveReport() {
  if(this.selectedReport) {
    const alreadyExistsInDb = this.selectedReport.powerBiReportId ? true : false;

    this.selectedReport.name = this.tempName;
    this.selectedReport.description = this.tempDescription;
    this.selectedReport.workSpaceId = this.tempWorkSpaceId;
    this.selectedReport.id = this.tempReportId;
    this.selectedReport.image = this.tempImageBase64;

    if(alreadyExistsInDb) {

      this.httpService.UpdateReport(this.selectedReport).subscribe(success => {
        if(this.selectedReport){
          this.openSnackBar(`Success: Updated ${this.selectedReport.name}`, 'close', this.snackbarModes.success);
        }

        });
    } else {
      //save

      if(this.issubCategoryLastSelected) {
        this.selectedReport.subCategoryId = this.selectedSubCategory?.id as string;
      } else {
        this.selectedReport.subCategoryId = this.selectedCategory?.id as string;
      }

      this.selectedReport.sequence = this.reports ? this.reports.length : 0;

      this.httpService.CreateReport(this.selectedReport).subscribe(Id => {
        if(this.selectedReport) {
          this.selectedReport.isNew = false;
          this.selectedReport.powerBiReportId = Id;
          if(this.reports)
            this.reports.push(this.selectedReport)
          else
            this.reports = [this.selectedReport];

          this.openSnackBar(`Success: Created ${this.selectedReport.name}`, 'close', this.snackbarModes.success);
        }

        });
    }

  }
 }


 deleteSubjectArea() {
  if(this.selectedSubjectArea) {

    this.httpService.DeleteSubjectArea(this.selectedSubjectArea?.id).subscribe(success => {
      if(this.selectedSubjectArea) {
        this.openSnackBar(`Success: Deleted ${this.selectedSubjectArea.name}`, 'close', this.snackbarModes.success);
      }
      const index = this.subjectAreas.findIndex((i) => {
        return i.id === this.selectedSubjectArea?.id;
      })
      this.subjectAreas.splice(index, 1);
      });
  }
 }

 deleteCategory() {
  if(this.selectedCategory) {

    this.httpService.DeleteCategory(this.selectedCategory?.id).subscribe(success => {
      if(this.selectedCategory) {
        this.openSnackBar(`Success: Deleted ${this.selectedCategory.name}`, 'close', this.snackbarModes.success);
      }
      const index = this.categories.findIndex((i) => {
        return i.id === this.selectedCategory?.id;
      })
      this.categories.splice(index, 1);
      });
  }
 }

 deleteSubCategory() {
  if(this.selectedSubCategory) {

    this.httpService.DeleteSubCategory(this.selectedSubCategory?.id).subscribe(success => {
      if(this.selectedSubCategory) {
        this.openSnackBar(`Success: Deleted ${this.selectedSubCategory.name}`, 'close', this.snackbarModes.success);
      }

      const index = this.subCategories.findIndex((i) => {
        return i.id === this.selectedSubCategory?.id;
      })
      this.subCategories.splice(index, 1);

      });
  }
 }

 deleteReport() {
    if(this.selectedReport) {

      this.httpService.DeleteReport(this.selectedReport?.powerBiReportId).subscribe(success => {
        if(this.selectedReport) {
          this.openSnackBar(`Success: Deleted ${this.selectedReport.name}`, 'close', this.snackbarModes.success);
        }


        const index = this.reports.findIndex((i) => {
          return i.powerBiReportId === this.selectedReport?.powerBiReportId;
        })
        this.reports.splice(index, 1);



        });
    }

  }


  openSnackBar(message: string, action: string, mode: number) {

    if(mode == snackbarModes.success){
      this._snackBar.open(message, action, {
        duration: 4,
        panelClass: ''
      });
    } else if (mode == snackbarModes.error) {
      this._snackBar.open(message, action, {
        duration: 4,
        panelClass: ''
      });
    }

  }
}
