import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../models/app.config';
import { ConfigService } from './config.service';
import { NewsInsights, UpdateAdGroupNewsInsightsRequest } from '../models/newsInsights';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsInsightsServiceService {
  baseUrl: string;
  config!: AppConfig;
  constructor(private httpClient: HttpClient, private configService: ConfigService, private router: Router) {
    //debugger;
    this.config = this.configService.readConfig() as AppConfig;
    this.baseUrl = `${this.config.apiUrl}/api`;


  }

  updateNewAndInstightsbyAdGroupId(adGroupId: string, request: UpdateAdGroupNewsInsightsRequest): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/adgroup/${adGroupId}/newsinsights`;
    return this.httpClient.post<boolean>(requestUrl, request);
  }

  getNewsInsightsForAdGroup(adGroupId: string): Observable<NewsInsights[]> {
    const requestUrl = `${this.baseUrl}/adgroup/${adGroupId}/newsinsights`;
    return this.httpClient.get<NewsInsights[]>(requestUrl);
  }

}
