import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../models/app.config';
import { ConfigService } from './config.service';
import { Observable, Subject } from 'rxjs';
import { TreeLayout } from '../models/treeLayout';
import { EditTreeNodeRequest, TreeSortRequest } from '../models/TreeSortNode';
import { BreadCrumb } from '../models/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class TreeHelperServiceService {
  baseUrl: string;
  config!: AppConfig;

  adGroupId!: string;

  treeLayoutLoading = true;
  treeLayout!: TreeLayout;
  treeLayoutChange: Subject<TreeLayout> = new Subject<TreeLayout>;

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
    this.config = this.configService.readConfig() as AppConfig;
    this.baseUrl = `${this.config.apiUrl}/api`;

    this.treeLayoutChange.subscribe((subjectAreas) => {
      this.treeLayout = subjectAreas;
      this.treeLayoutLoading = false;
    })
  }

  getTreeLayout(nodeType: string, id: string): Observable<TreeLayout> {
    if(!nodeType || !id) {
      return {} as Observable<TreeLayout>;
    }
    this.treeLayoutLoading = true;
    const requestUrl = `${this.baseUrl}/treeHelper/${nodeType}/${id}`;
    const observable = this.httpClient.get<TreeLayout>(requestUrl);
    observable.subscribe((treeLayout)=>{
      this.treeLayoutChange.next(treeLayout);
    });

    return observable;
  }

  SortNodes(request: TreeSortRequest): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/treeHelper/sort`;
    return this.httpClient.post<boolean>(requestUrl, request);
  }

   editNode(request: EditTreeNodeRequest): Observable<boolean> {
    const requestUrl = `${this.baseUrl}/treeHelper/edit`;
    return this.httpClient.post<boolean>(requestUrl, request);
  }

  getBreadCrumbForReportId(reportId: string): Observable<BreadCrumb> {
    const requestUrl = `${this.baseUrl}/treeHelper/breadcrumb/${reportId}`;
    return this.httpClient.get<BreadCrumb>(requestUrl);
  }
}
