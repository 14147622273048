<div class="mouseexittriggerdiv" (mouseenter)="hideList()"></div>
<div class="spinner" *ngIf="httpService.megamenuLoading">
  <mat-spinner color="#00263E" style="height: 30px"></mat-spinner>
</div>

<div id="megamenuOffsetDiv" *ngIf="!httpService.megamenuLoading" (mouseenter)="enteredArea()">
  <nav *ngIf="show">
    <div id="DriveUXnavigationmain">
      <div id="DriveUXnavigation" class="uni-pad">
        <div *ngIf="!userHasAcceess && subjectAreas.length == 0" style="display: flex; flex-direction: row; justify-content: center">
          <h5 style="padding: 0">
            <b>{{ userNotInCorrectAdGroup }}</b>
          </h5>
          <div style="margin-top: 3px; padding-left: 10px">
            <a target="_blank" href="https://ajg0.sharepoint.com/sites/GGBUS-Gdrive/SitePages/home.aspx">U.S. Employees</a>
          </div>
          <div style="margin-top: 3px; padding-left: 10px">
            <a target="_blank" href="https://ajguk.sharepoint.com/sites/GGBUK-marcomms-ClientServicingToolkit/SitePages/inner-pages/Sales_Operations_subpage2.aspx">U.K. employees</a>
          </div>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: space-between">
          <div *ngFor="let subjectArea of subjectAreas.slice(0, 6); index as i" style="cursor: pointer" id="DriveUXnavigation1" (mouseenter)="subjectAreaSelected(subjectArea, i)">
            <div style="display: flex; flex-direction: row">
              <div id="drive1img"><img [src]="subjectArea.image" class="preview megamenuIcons" /></div>
              <span class="megamenuText">{{ subjectArea.name }}</span
              ><img src="../../../assets/images/Gallagherdropicon.svg" alt="" id="rightarrowofmenu" style="margin-left: 5px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <!-- Mega Menu -->
  <div *ngIf="show && showList && !megamenuService.headerHoveredLast" id="MegaMenumain">
    <div
      [ngClass]="{
        megamenubottom1: selectedSubjectAreaIndex == 0,
        megamenubottom2: selectedSubjectAreaIndex == 1,
        megamenubottom3: selectedSubjectAreaIndex == 2,
        megamenubottom4: selectedSubjectAreaIndex == 3,
        megamenubottom5: selectedSubjectAreaIndex == 4,
        megamenubottom6: selectedSubjectAreaIndex == 5
      }"
      *ngIf="true"
      id="MegaMenumain"
    >
      <div id="DriveUXmegamenumain" (mouseleave)="hideList()">
        <div
          [ngClass]="{
            DriveUXmegamenutri1: selectedSubjectAreaIndex == 0,
            DriveUXmegamenutri2: selectedSubjectAreaIndex == 1,
            DriveUXmegamenutri3: selectedSubjectAreaIndex == 2,
            DriveUXmegamenutri4: selectedSubjectAreaIndex == 3,
            DriveUXmegamenutri5: selectedSubjectAreaIndex == 4,
            DriveUXmegamenutri6: selectedSubjectAreaIndex == 5
          }"
        >
          <img src="../../../assets/images/megamenutriangle.svg" alt="" />
        </div>
        <div id="DriveUXmegamenu">
          <div id="DriveUXSalesRetentionmain">
            <div id="DriveUXSalesRetention">
              <img [src]="selectedSubjectArea?.image" class="preview megamenugLargeIcon" />
              <h5 style="padding: 0px" class="megamenugLargeText">
                <b>{{ selectedSubjectArea?.name }}</b>
              </h5>
              <p style="padding: 0px">{{ selectedSubjectArea?.description }}</p>
            </div>
          </div>

          <div id="DriveUXMegamenufistmain">
            <div id="DriveUXMegamenufist">
              <ul>
                <li style="cursor: pointer" class="menuTree" (mouseenter)="categorySelected(category)" *ngFor="let category of selectedSubjectArea?.categories; index as i">
                  {{ category.name }} <i class="material-icons Driveright">arrow_forward_ios</i>
                </li>
              </ul>
            </div>
          </div>

          <div id="DriveUXMegamenumain1">
            <div id="DriveUXMegamenu1">
              <ul>
                <li style="cursor: pointer" class="menuTree" *ngFor="let subCategory of selectedCategory?.subCategories; index as i" (mouseenter)="subCategorySelected(subCategory)">
                  {{ subCategory.name }} <i class="material-icons Driveright">arrow_forward_ios</i>
                </li>
                <li style="cursor: pointer" class="menuTree" *ngFor="let report of selectedCategory?.driveReports; index as i" matTooltip="{{ report.description }}" (mouseenter)="removesubCategorySelected()" matTooltipPosition="right">
                  <div *ngIf="!report.externalUrl" (click)="navigateToReportView(report)">
                    {{ report.name }}
                  </div>
                  <div *ngIf="report.externalUrl" (click)="goToLink(report)">
                    {{ report.name }}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div id="DriveUXSalesmain">
            <div id="DriveUXSale">
              <ul>
                <li style="cursor: pointer" class="menuTree" *ngFor="let subCategoryTwo of selectedSubCategory?.subCategoryTwos; index as i" (mouseenter)="subCategoryTwoSelected(subCategoryTwo)">
                  {{ subCategoryTwo.name }}<i class="material-icons Driveright">arrow_forward_ios</i>
                </li>
                <li style="cursor: pointer" class="menuTree" *ngFor="let report of selectedSubCategory?.driveReports; index as i" (mouseenter)="removesubCategoryTwoSelected()" matTooltip="{{ report.description }}" matTooltipPosition="right">
                  <div *ngIf="!report.externalUrl" (click)="navigateToReportView(report)">
                    {{ report.name }}
                  </div>
                  <div *ngIf="report.externalUrl" (click)="goToLink(report)">
                    {{ report.name }}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div id="DriveUXSalesmain">
            <div id="DriveUXSale">
              <ul>
                <li style="cursor: pointer" class="menuTree" *ngFor="let report of selectedSubCategoryTwo?.driveReports; index as i" matTooltip="{{ report.description }}" matTooltipPosition="right">
                  <div *ngIf="!report.externalUrl" (click)="navigateToReportView(report)">
                    {{ report.name }}
                  </div>
                  <div *ngIf="report.externalUrl" (click)="goToLink(report)">
                    {{ report.name }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mega Menu -->
</div>
