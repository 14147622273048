import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppConfig } from '../models/app.config';
import { SubjectArea } from '../models/subject-area';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class MegamenuService {
  isInteractable = true;
  headerHoveredLast = false;
  mobileHeaderVisible: boolean = false;

  baseUrl: string;
  config!: AppConfig;
  megamenuLoading = true;

  userAssignedAdGroupsChange: Subject<any[]> = new Subject<any[]>;
  userLatestSubjectAreasChange: Subject<SubjectArea[]> = new Subject<SubjectArea[]>;
  userLatestSubjectAreasObservable: Observable<SubjectArea[]> = new Observable<SubjectArea[]>;

  public isAdminSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  userAzureAdGroups?: string[];

  username = '';
  adminAdGroupName = 'u-GallagherDriveInteractivePortal-Admin';

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.config = this.configService.readConfig() as AppConfig;
    this.baseUrl = `${this.config.apiUrl}/api`;
  }

  getAccessTokenAndCallGraphAPI(accessToken: string, username: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken
      })
    }

    this.http.get(`https://graph.microsoft.com/v1.0/users/${username}/memberOf?$select=id,displayName&$top=999`, httpOptions).toPromise().then(result => {
      this.userAssignedAdGroupsChange.next((result as any).value);
    });

  }

  isAdminCheck(): void {
    // Used for testing with a role we do have at the time: isAdmin = this.userAzureAdGroups.includes('u-gts-planview-user-pilot');

    if (this.userAzureAdGroups) {
      const isAdmin = this.userAzureAdGroups.includes(this.adminAdGroupName);
      this.isAdminSubject.next(isAdmin);
    }
    else
      console.error("No ad group assigned");
  }

  getMegaMenuSubjectAreaForAdGroupNames(adGroupNames?: string[]): Observable<SubjectArea[]> {
    if (adGroupNames) {
      this.userAzureAdGroups = adGroupNames;
      this.isAdminCheck();

    }
    else
      console.error("No user groups assignment!")
    const requestUrl = `${this.baseUrl}/subjectarea/megamenu/adgroup/names`;
    const observable = this.http.post<SubjectArea[]>(requestUrl, adGroupNames);

    observable.subscribe(data => {
      const now = new Date();
      localStorage.setItem("MEGAMENU_SUBJECTAREAS_BYNAME_LAST_CACHE_DATE", now.toString());
      localStorage.setItem("MEGAMENU_SUBJECTAREAS_BYNAME_VALUE", JSON.stringify(data));
      localStorage.setItem("USERS_AZURE_ADGROUPS", JSON.stringify(adGroupNames));
      this.userLatestSubjectAreasChange.next(data);
    });

    return observable;
  }
}
