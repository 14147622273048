import { Component, Input } from '@angular/core';
import { ToolsCalculatorModel } from 'src/app/models/ToolsCalculatorModel';
import { WindowServiceService } from 'src/app/services/window-service.service';

@Component({
  selector: 'app-tool-calculators-static-viewer',
  templateUrl: './tool-calculators-static-viewer.component.html',
  styleUrls: ['./tool-calculators-static-viewer.component.scss']
})
export class ToolCalculatorsStaticViewerComponent {
  @Input() newsInsightsList!: ToolsCalculatorModel[] | undefined;
  baseDir = '../../../assets/images/ToolsCalculators';
  mobileView = false;

  ngOnInit(): void {
    const d1 = {
      title: 'Umbrella Limits Calculator',
      callToAction: 'Start Your Comparison',
      url: 'https://umbrellalimits.tools.ajg.com/',
      reportId: '002FDF42-8E54-4B75-B93B-75BE663E7E2D',
      description: "Tell us five (5) basic things about your business and we'll use Gallagher analytics to show you how your Umbrella Policy limits compare to companies like yours—including target limit and exposure data with real-world claim examples."
    } as ToolsCalculatorModel;

    const d2 = {
      title: 'Cyber Liability Calculator',
      callToAction: 'Start Your Comparison',
      url: 'https://cyberrisk.tools.ajg.com/',
      reportId: '6BCC86CC-C387-4075-8E9C-3102524BA2F4',
      description: "Tell us five (5) things about your business and we'll use Gallagher analytics to calculate and estimate the optimal Cyber Liability coverage limits for your level of exposure in case of a cyber breach or ransomware attack."
    } as ToolsCalculatorModel;

    this.newsInsightsList = [d1, d2] as ToolsCalculatorModel[];
  }
  getImageDir(count: number) {
    return `${this.baseDir}${count + 1}.png`
  }
  constructor(public windowsService: WindowServiceService) {
  }
  routeToUrl(url: string) {

    window.open(url, "_blank");

  }
}
