import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NewsInsights, UpdateAdGroupNewsInsightsRequest } from 'src/app/models/newsInsights';
import { NewsInsightsServiceService } from 'src/app/services/news-insights-service.service';

@Component({
  selector: 'app-news-insights-editor',
  templateUrl: './news-insights-editor.component.html',
  styleUrls: ['./news-insights-editor.component.scss']
})
export class NewsInsightsEditorComponent implements OnChanges, OnInit {
  @Input() newsInsightsList!: NewsInsights[] | undefined;
  @Input() adGroupId!: string | undefined;
  insightsNewsFormGroup: FormGroup;
  newsInsightsFormArray!: FormArray;

  ngOnChanges(changes: SimpleChanges) {
  //  console.log(changes['newsInsightsList']);
    if (changes['newsInsightsList'] && changes['newsInsightsList'].currentValue) {
      // debugger;
      const listNewsAndInsights = changes['newsInsightsList'].currentValue;

      if (listNewsAndInsights && listNewsAndInsights.length > 0) {
        this.addNewsInsights(listNewsAndInsights);
      } else {
        this.addBlankNewsInsights();
      }
    }
  }

  isVisible() {
    if (this.adGroupId)
      return true;
    return false;
  }

  ngOnInit(): void {
    if (!this.newsInsightsList || this.newsInsightsList.length < 1) {
      this.addBlankNewsInsights();
    }

  }

  getControls() {
    const formArray = this.insightsNewsFormGroup.get('newsInsightsFormArray') as FormArray;

    if (formArray) {
      const controls = formArray.controls;
      return controls
    }

    return (new FormArray([])).controls
  }

  constructor(private fb: FormBuilder, private _newsInsightsService: NewsInsightsServiceService) {
    this.insightsNewsFormGroup = new FormGroup({
      newsInsightsFormArray: new FormArray([])
    });

  }

  addNewsInsights(incomingListOfNewsAndInsights: NewsInsights[]) {
    this.newsInsightsFormArray = this.insightsNewsFormGroup.get('newsInsightsFormArray') as FormArray;
    this.newsInsightsFormArray.clear()// = this.fb.array([]);

    incomingListOfNewsAndInsights.forEach(x => {
      this.newsInsightsFormArray.push(this.fb.group({
        title: new FormControl(x.title, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(45),
        ]),
        description: new FormControl(x.description, [
          Validators.required,
        ]),
        callToAction: new FormControl(x.callToAction, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(45),
        ]),
        url: new FormControl(x.url, [
          Validators.required,
          Validators.minLength(1),
        ]),
        sequence: x.sequence
      }));
    })

  }

  addBlankNewsInsights() {
    this.newsInsightsFormArray = this.insightsNewsFormGroup.get('newsInsightsFormArray') as FormArray;
    this.newsInsightsFormArray.clear()
    for (let i = 0; i < 3; i++) {
      this.newsInsightsFormArray.push(this.fb.group({
        title: new FormControl('', [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(45),
        ]),
        description: new FormControl('', [
          Validators.required,
        ]),
        callToAction: new FormControl('', [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(45),
        ]),
        url: new FormControl('', [
          Validators.required,
          Validators.minLength(1),
        ]),
        sequence: i
      }));
    }
  }


  formValid() {
    const dirty = this.insightsNewsFormGroup.dirty;
    const formValid = this.insightsNewsFormGroup.valid;
    return dirty && formValid;
  }

  save() {
    this.newsInsightsFormArray = this.insightsNewsFormGroup.get('newsInsightsFormArray') as FormArray;
    const request: UpdateAdGroupNewsInsightsRequest = {
      newsInsights: this.newsInsightsFormArray.value
    };

    this._newsInsightsService.updateNewAndInstightsbyAdGroupId(this.adGroupId as string, request).subscribe(x => {
      console.log(x);
    })
  }
}
