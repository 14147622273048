<mat-toolbar class="loginToolBar">
  <button mat-button color="basic">Contact Us</button>
  <button mat-button color="basic">Log In</button>
</mat-toolbar>
<mat-toolbar class="logoToolBar">
  <mat-toolbar-row>
    <img class="logo" src="../../../assets/images/gallagher.png" alt="" />
    <mat-divider [vertical]="true"></mat-divider>
    <span class="logoText">Drive</span>
    <span class="spacer"></span>
    <mat-divider></mat-divider>
  </mat-toolbar-row>
</mat-toolbar>
<!-- KEEP! -->
<router-outlet></router-outlet>
<!-- <app-header-menu></app-header-menu>
<app-report-manager></app-report-manager> -->
