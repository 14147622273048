<div class="center">
  <div class="sidePanel">
    <span>Recent Conversations</span>
  </div>

  <div class="container">
    <div id="GallagherLogo">
      <a href=""><img src="../../../assets/images/recommendHeader.svg" alt="" /></a>
    </div>

    <mat-card class="messageBox">
      <mat-card-content style="height: 100%">
        <div style="overflow: scroll; height: 100%">
          <div style="padding: 30px" class="messageHolder" *ngFor="let message of messages">
            <div *ngIf="!message.isUser" style="display: flex; flex-direction: column">
              <a href=""><img src="../../../assets/images/bot.svg" alt="" /></a>
            </div>

            <div *ngIf="message.isUser" style="display: flex; flex-direction: column">
              <a href=""><img src="../../../assets/images/avatar.svg" alt="" /></a>
            </div>

            <app-typewriter-card [message]="message" [linkButtons]="linkButtons"> </app-typewriter-card>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card style="height: max-content" class="inputBox">
      <mat-card-content style="padding-bottom: 0px !important">
        <div style="display: flex; flex-direction: row; justify-content: start">
          <div style="width: 42vw; margin-right: 35px">
            <form [formGroup]="form" action="" class="report-form">
              <mat-form-field style="width: 100%" class="input" appearance="outline">
                <mat-icon matPrefix>search</mat-icon>
                <input formControlName="searchBoxMessage" (keydown.enter)="send(form.controls.searchBoxMessage.value)" type="tel" placeholder="Search on report title or keywords" matInput />
                <mat-error>Description is required</mat-error>
              </mat-form-field>
            </form>
          </div>
          <div id="cancel-save-buttons">
            <a style="padding-right: 5px" (click)="openDisclosure()" value="Submit"><img style="height: 46px" src="../../../assets/images/Disclosure.svg" alt="" /></a>
            <a style="padding-right: 5px" (click)="openGptHelp()" value="Submit"><img style="height: 46px" src="../../../assets/images/Help.svg" alt="" /></a>

            <a style="padding-right: 5px" value="Submit" (click)="send(form.controls.searchBoxMessage.value)"><img style="height: 46px" src="../../../assets/images/Send.svg" alt="" /></a>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
