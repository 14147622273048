import { Component, Input } from '@angular/core';
import { NewsInsights } from 'src/app/models/newsInsights';
import { WindowServiceService } from 'src/app/services/window-service.service';

@Component({
  selector: 'app-drive-features-viewer',
  templateUrl: './drive-features-viewer.component.html',
  styleUrls: ['./drive-features-viewer.component.scss']
})

export class DriveFeaturesViewerComponent {
  @Input() featuredReportList!: NewsInsights[] | undefined;
  baseDir = '../../../assets/images/DriveFeatures-';
  mobileView = false;
  @Input() headerName = '';

  getImageDir(count: number) {
    return `${this.baseDir}${count + 1}.png`
  }

  constructor(public windowsService: WindowServiceService) {

  }
  ngOnInit(): void {
    if (this.headerName == 'Featured Reports') {
      this.baseDir = '../../../assets/images/FeaturedReports';
    }
    else if (this.headerName == 'News & Insights') {
      this.baseDir = '../../../assets/images/NewsInsights';
    }
    else if (this.headerName == 'Drive Features') {
      this.baseDir = '../../../assets/images/DriveFeatures';
    }
  }

  routeToUrl(url: string) {

    window.open(url, "_blank");

  }

  getMobileGridRowOne() {
    return this.featuredReportList?.slice(0, 2);
  }

  getMobileGridRowTwo() {
    return this.featuredReportList?.slice(2, 4);
  }
}
