<div class="newsInsightsWrapper" *ngIf="!windowsService.isMobileLayout">
  <div class="newsInsightsContent">
    <div class="newsInsightsHeader">Tools & Calculators</div>

    <div class="cardListContainer">
      <mat-card class="example-card" appearance="outlined" *ngFor="let ni of newsInsightsList; index as i">
        <mat-card-content>
          <div style="display: flex; justify-content: space-evenly; align-items: stretch;">
            <div style="flex: 0 0 50%; display: flex; flex-direction: column; justify-content: center;" class="imageContainer">
              <img class="sideImage" (click)="routeToUrl(ni.url)" [src]="getImageDir(i)" alt="" />
            </div>
            <div class="newsInsightsSubHeader" style="flex: 1; display: flex; flex-direction: column; justify-content: space-between;">
              <div>
                <span> {{ ni.title }}</span>
              </div>
              <div>
                <p class="description">
                  {{ ni.description }}
                </p>
              </div>
              <div>
                <a class="learnMoreUrls ctaLink" target="_blank" [href]="ni.url">{{ ni.callToAction }}</a>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div class="newsInsightsWrapper" *ngIf="windowsService.isMobileLayout">
  <div class="newsInsightsContent">
    <div class="newsInsightsHeader">Tools & Calculators</div>
    <div class="newsInsightsRealTimeContent-MiniMobile">
      <div class="cardListContainer-MiniMobile">
        <mat-card [class.mat-elevation-z2]="true" class="example-card-MiniMobile" appearance="outlined" *ngFor="let ni of newsInsightsList; index as i">
          <img (click)="routeToUrl(ni.url)" class="sectionHeaderImage" [src]="getImageDir(i)" alt="" />
          <mat-card-content>
            <div class="newsInsightsSubHeader-mobile">
              {{ ni.title }}
            </div>
            <p class="description-mobile">
              {{ ni.description }}
            </p>
          </mat-card-content>
          <mat-card-actions>
            <a class="learnMoreUrls ctaLink" target="_blank" [href]="ni.url">{{ ni.callToAction }}</a>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>
