<div class="diagHeader">
  <h1>Manage Reports</h1>
</div>
<mat-card class="settingCard">
  <mat-card-header>
    <mat-card-title>
      <span *ngIf="selectedMode == viewModes.subjectArea"> Subject Area </span>
      <span *ngIf="selectedMode == viewModes.category"> Category </span>
      <span *ngIf="selectedMode == viewModes.subCategory"> Category </span>
      <span *ngIf="selectedMode == viewModes.report"> Report </span>
    </mat-card-title>
  </mat-card-header>

  <div *ngIf="selectedMode == viewModes.subjectArea">
    <form class="form">
      <mat-form-field appearance="outline" class="settingField">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="tempName" name="tempName" placeholder="Subject Area Name.." />
      </mat-form-field>

      <mat-form-field appearance="outline" class="settingField">
        <mat-label>description</mat-label>
        <input [(ngModel)]="tempDescription" name="tempDescription" matInput placeholder="Subject Area Description.." />
      </mat-form-field>

      <app-upload-images class="settingField" (base64StringUpdated)="updateImageBase64String($event)"></app-upload-images>

      <country-select (selectedCountriesEmitted)="selectectCountriesUpdated($event)" [allCountries]="countries" class="settingField countrySelect"></country-select>
    </form>
  </div>

  <div *ngIf="selectedMode == viewModes.category">
    <form class="form">
      <mat-form-field appearance="outline" class="settingField">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="tempName" name="tempName" placeholder="Category Name.." />
      </mat-form-field>

      <mat-form-field appearance="outline" class="settingField">
        <mat-label>description</mat-label>
        <input matInput [(ngModel)]="tempDescription" name="tempDescription" placeholder="Category Description.." />
      </mat-form-field>

      <app-upload-images class="settingField" (base64StringUpdated)="updateImageBase64String($event)"></app-upload-images>
    </form>
  </div>

  <div *ngIf="selectedMode == viewModes.subCategory">
    <form class="form">
      <mat-form-field appearance="outline" class="settingField">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="tempName" name="tempName" placeholder="Subategory Name.." />
      </mat-form-field>

      <mat-form-field appearance="outline" class="settingField">
        <mat-label>description</mat-label>
        <input matInput [(ngModel)]="tempDescription" name="tempDescription" placeholder="SubCategory Description.." />
      </mat-form-field>

      <app-upload-images class="settingField" (base64StringUpdated)="updateImageBase64String($event)"></app-upload-images>
    </form>
  </div>

  <div *ngIf="selectedMode == viewModes.report">
    <form class="form">
      <mat-form-field appearance="outline" class="settingField">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="tempName" name="tempName" placeholder="Report Name.." />
      </mat-form-field>

      <mat-form-field appearance="outline" class="settingField">
        <mat-label>description</mat-label>
        <input matInput [(ngModel)]="tempDescription" name="tempDescription" placeholder="Report Description.." />
      </mat-form-field>

      <mat-form-field class="settingField" appearance="outline">
        <mat-label>Work Space</mat-label>
        <mat-icon matPrefix>cases</mat-icon>
        <mat-select (selectionChange)="groupDropdownChanged($event)" [(value)]="tempWorkSpaceId">
          <mat-option *ngFor="let biGroup of groupIdDropdownValues" [value]="biGroup.id">{{ biGroup.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="settingField" appearance="outline">
        <mat-label>Report Id</mat-label>
        <mat-icon matPrefix>cases</mat-icon>
        <mat-select [(value)]="tempReportId">
          <mat-option *ngFor="let biReport of reportIdDropdownValues" [value]="biReport.id">{{ biReport.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <app-upload-images class="settingField" (base64StringUpdated)="updateImageBase64String($event)"></app-upload-images>
    </form>
  </div>
  <div>
    <img [src]="tempImageBase64" class="preview" />
  </div>
  <mat-card-actions>
    <div *ngIf="selectedMode == viewModes.subjectArea">
      <button mat-stroked-button (click)="saveSubjectArea()">Save</button>
      <button mat-stroked-button (click)="deleteSubjectArea()">Delete</button>
    </div>

    <div *ngIf="selectedMode == viewModes.category">
      <button mat-stroked-button (click)="saveCategory()">Save</button>
      <button mat-stroked-button (click)="deleteCategory()">Delete</button>
    </div>

    <div *ngIf="selectedMode == viewModes.subCategory">
      <button mat-stroked-button (click)="saveSubCategory()">Save</button>
      <button mat-stroked-button (click)="deleteSubCategory()">Delete</button>
    </div>

    <div *ngIf="selectedMode == viewModes.report">
      <button mat-stroked-button (click)="saveReport()">Save</button>
      <button mat-stroked-button (click)="deleteReport()">Delete</button>
    </div>
  </mat-card-actions>
</mat-card>
<br />
<mat-card class="settingCard">
  <div class="groupList">
    <mat-list class="settingList" role="list">
      <mat-list-item class="firstItem" role="listitem">
        Subject Area

        <button class="addButton" mat-mini-fab (click)="addSubjectAreaView()">
          <mat-icon class="addIcon">add</mat-icon>
        </button>
      </mat-list-item>
      <mat-divider class="headDivider"></mat-divider>
      <div *ngFor="let subjectArea of getSubjectAreas()">
        <mat-list-item role="listitem">
          <button class="countButton" mat-mini-fab>
            {{ getCategoryCount(subjectArea) }}
          </button>
          <a (click)="subjectAreaSelected(subjectArea)">
            {{ subjectArea.name }}
          </a>
        </mat-list-item>
        <mat-divider></mat-divider>
      </div>
    </mat-list>

    <mat-list class="settingList" role="list">
      <mat-list-item class="firstItem" role="listitem">
        Category

        <button class="addButton" mat-mini-fab (click)="addCategoryView()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-list-item>
      <mat-divider class="headDivider"></mat-divider>
      <div *ngFor="let category of categories">
        <mat-list-item role="listitem">
          <button class="countButton" mat-mini-fab>
            {{ getReportCountForCategory(category) }}
          </button>
          <a (click)="categorySelected(category)">
            {{ category.name }}
          </a>
        </mat-list-item>
        <mat-divider></mat-divider>
      </div>
    </mat-list>

    <mat-list class="settingList" role="list">
      <mat-list-item class="firstItem" role="listitem">
        Sub-Category

        <button class="addButton" mat-mini-fab (click)="addSubCategoryView()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-list-item>
      <mat-divider class="headDivider"></mat-divider>
      <div *ngFor="let subCategory of subCategories">
        <mat-list-item role="listitem">
          <button class="countButton" mat-mini-fab>
            {{ getReportCountForSubCategory(subCategory) }}
          </button>
          <a (click)="subCategorySelected(subCategory)">
            {{ subCategory.name }}
          </a>
        </mat-list-item>
        <mat-divider></mat-divider>
      </div>
    </mat-list>

    <mat-list class="settingList" role="list">
      <mat-list-item class="firstItem" role="listitem">
        Report

        <button class="addButton" mat-mini-fab (click)="addReportView()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-list-item>
      <mat-divider class="headDivider"></mat-divider>
      <div *ngFor="let report of reports">
        <mat-list-item role="listitem">
          <a (click)="reportSelected(report)">
            {{ report.name }}
          </a>
        </mat-list-item>
        <mat-divider></mat-divider>
      </div>
    </mat-list>
  </div>
</mat-card>
