<mat-card style="height: max-content; padding: 20px; width: 55vw" class="message userMessage" [ngClass]="{ userMessage: message.isUser, gptMessage: !message.isUser }">
  <div [innerHTML]="typedText"></div>
  <div #template></div>
  <ul *ngIf="!message.fillerMessage && showLinkedButtons">
    <li *ngFor="let lb of linkButtons">
      <a (click)="test(lb.url)">{{ lb.text }}</a>
    </li>
  </ul>

  <!-- {{message.prompt}} -->
</mat-card>
