<mat-sidenav-container class="example-container" autosize>
  <mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
    <div class="sidenavContent">
      <mat-nav-list>
        <div *ngFor="let subjectArea of subjectAreas; index as i">
          <mat-list-item (click)="showSubmenu(i)" class="parent">
            <span class="full-width" *ngIf="isExpanded || isShowing">{{ subjectArea.name }}</span>

            <mat-icon class="menu-button" [ngClass]="{ rotated: getIsShown(i) }" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
          </mat-list-item>

          <div *ngFor="let category of subjectArea?.categories" class="submenu" [ngClass]="{ expanded: showSubmenu }">
            <mat-list-item (click)="showSubSubMenu = !showSubSubMenu" class="parent">
              <span class="full-width" *ngIf="isExpanded || isShowing">{{ category.name }}</span>
              <mat-icon class="menu-button" [ngClass]="{ rotated: showSubSubMenu }" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{ expanded: showSubSubMenu }" *ngIf="isShowing || isExpanded">
              <mat-list-item (click)="setReportId(driveReport.powerBiReportId)" *ngFor="let driveReport of category?.driveReports">{{ driveReport.name }}</mat-list-item>
            </div>
          </div>
        </div>
      </mat-nav-list>

      <img class="logo" src="../../../assets/images/SalesAndRetention.png" alt="" />
    </div>
  </mat-sidenav>

  <div class="example-sidenav-content">
    <app-report-viewer [currentReportId]="currentViewedReportId" class="full-width"></app-report-viewer>
  </div>
</mat-sidenav-container>
