<div class="container">
  <h1 mat-dialog-title>Edit</h1>

  <form [formGroup]="form" class="report-form">
    <div style="display: flex; flex-direction: column">
      <label>Name</label>
      <mat-form-field class="input" appearance="outline">
        <input formControlName="name" type="tel" matInput />
      </mat-form-field>
    </div>

    <div style="display: flex; flex-direction: column">
      <label>Description</label>
      <mat-form-field class="input" appearance="outline">
        <input formControlName="description" type="tel" matInput />
      </mat-form-field>
    </div>

    <div style="display: flex; flex-direction: column">
      <mat-form-field class="example-form-field">
        <mat-label>Tags</mat-label>
        <mat-chip-grid #chipGrid aria-label="Enter tags">
          <mat-chip-row *ngFor="let tag of tags" (removed)="removeTag(tag)">
            {{ tag }}
            <button matChipRemove aria-label="'remove ' + tag">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input placeholder="New tag..." [matChipInputFor]="chipGrid" (matChipInputTokenEnd)="add($event)" />
      </mat-form-field>
    </div>
    <div *ngIf="data?.url" style="display: flex; flex-direction: column">
      <mat-checkbox formControlName="externalUrl">External URL (Open new browser tab)</mat-checkbox>
    </div>

    <div *ngIf="data?.url" style="display: flex; flex-direction: column">
      <label>Url</label>
      <mat-form-field class="input" appearance="outline">
        <input formControlName="url" type="tel" matInput />
      </mat-form-field>
    </div>

    <img [src]="tempImageBase64" class="previewImageUpload" />
    <app-upload-images class="settingField" (base64StringUpdated)="updateImageBase64String($event)"></app-upload-images>

    <div style="display: flex; flex-direction: column; width: 100%">
      <label><span style="color: red">*</span> Work Space</label>
      <mat-form-field class="input" appearance="outline" style="margin-right: 30px">
        <mat-icon matPrefix>cases</mat-icon>
        <mat-select formControlName="workSpaceId">
          <mat-option *ngFor="let biGroup of groupIdDropdownValues" [value]="biGroup.id">{{ biGroup.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div style="display: flex; flex-direction: column; width: 100%">
      <label><span style="color: red">*</span> Report Id</label>
      <mat-form-field class="input" appearance="outline" style="">
        <mat-icon matPrefix>cases</mat-icon>
        <input type="text" placeholder="Report" matInput [formControl]="form.controls.powerBiReportId" [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn(filteredReportIdDropdownValues)">
          <mat-option *ngFor="let biReport of filteredReportIdDropdownValues" [value]="biReport.id">{{ biReport.name }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </form>
  <div id="cancel-save-buttons">
    <button mat-stroked-button color="warn" style="margin-right: 6px" (click)="cancel()" value="Submit">Cancel</button>
    <button mat-stroked-button style="margin-right: 6px" color="primary" (click)="save()" value="Submit">Save</button>
  </div>
</div>
