import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowServiceService {
  isMobileLayout: boolean = false;
  isMiniMobileLayout: boolean = false;
  breadCrumbViewLayout: boolean = false;
  constructor() { 
     window.onresize = () => {
      this.isMobileLayout = window.innerWidth <= 1269;
      this.isMiniMobileLayout = window.innerWidth <= 800;
      this.breadCrumbViewLayout = window.innerWidth > 1280;
     };
     window.dispatchEvent(new Event('resize'));
    }
}
