import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlViewerServiceService {
  private url = '';
  private associatedReportId = '';

  getLatestUrl() {
    if (this.url) {
      return this.url;
    } else {
      return localStorage.getItem("latestReportUrl");
    }
  }

  getLatestUrlAssociatedReportId() {
    if (this.associatedReportId) {
      return this.associatedReportId;
    } else {
      return localStorage.getItem("latestReportUrlAssociatedReportId");
    }
  }

  setLatestUrl(url: string, reportId: string = '') {
    this.url = url;
    if (reportId)
      localStorage.setItem("latestReportUrlAssociatedReportId", reportId);
    localStorage.setItem("latestReportUrl", url);
  }

  constructor() { }
}
