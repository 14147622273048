import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { AddReportRequest } from 'src/app/models/Requests/AddReportRequest';
import { CreateAdGroupRequest } from 'src/app/models/Requests/CreateAdGroupRequest';
import { CreateCategoryRequest } from 'src/app/models/Requests/CreateCategoryRequest';
import { CreateSubCategoryRequest } from 'src/app/models/Requests/CreateSubCategoryRequest';
import { CreateSubCategoryTwoRequest } from 'src/app/models/Requests/CreateSubCategoryTwoRequest';
import { CreateSubjectAreaRequest } from 'src/app/models/Requests/CreateSubjectAreaRequest';
import { AdGroup } from 'src/app/models/adGroup';
import { AppConfig } from 'src/app/models/app.config';
import { BiGroupDto, BiReportDto } from 'src/app/models/bi';
import { Category } from 'src/app/models/category';
import { DriveReport } from 'src/app/models/drive-report';
import { SubCategory } from 'src/app/models/sub-category';
import { SubCategoryTwo } from 'src/app/models/sub-categorytwo';
import { SubjectArea } from 'src/app/models/subject-area';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarModes } from 'src/app/models/enums/SnackbarModes';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeHelperServiceService } from 'src/app/services/tree-helper-service.service';
import { ErrorSnackbarComponent } from '../snack-bar/fail-snackbar/error-snackbar/error-snackbar.component';
import { SnackbarComponent } from '../snack-bar/snackbar/snackbar.component';
import { TreeLayout } from 'src/app/models/treeLayout';
@Component({
  selector: 'app-add-report',
  templateUrl: './add-report.component.html',
  styleUrls: ['./add-report.component.scss']
})
export class AddReportComponent implements OnInit {
  @Output() saveSuccess = new EventEmitter<boolean>();
  subjectAreas: SubjectArea[] = [];
  categories: Category[] = [];
  subCategories: SubCategory[] = [];
  subCategoryTwos: SubCategoryTwo[] = [];
  reports: DriveReport[] = [];
  config!: AppConfig;
  adGroupsMultiselectedIds = '';
  adGroups!: AdGroup[];
  selectedAdGroup!: AdGroup;
  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

  countries: string[] = [];
  reportIdDropdownValues: BiReportDto[] = [{ name: 'Global Report', id: 'd2887447-ec87-4cd6-af17-caebfee0b37e' } as BiReportDto];
  filteredReportIdDropdownValues!: BiReportDto[];
  tempImageBase64: string = '';

  editMode = false;
  selectedReportTabIndex: number = 0;

  private _groupIdDropdownValues: BiGroupDto[] = [{ name: 'AJG WorkSpace', id: '42063e3c-aafa-4c32-82c2-83705889a444' } as BiGroupDto];
  set groupIdDropdownValues(val) {
    this._groupIdDropdownValues = val

    const t = this.reportForm.controls.workSpace.value;
    this.reportForm.controls.workSpace.setValue(t);
    this.reportForm.controls.workSpace.markAsDirty();
  }
  get groupIdDropdownValues() {
    return this._groupIdDropdownValues
  }
  constructor(public httpService: HttpService, private _sanitizer: DomSanitizer, private _snackBar: MatSnackBar, private _router: Router, private treeHelper: TreeHelperServiceService, private route: ActivatedRoute) { }


  reportForm = new FormGroup({
    countries: new FormControl(''),
    adGroup: new FormControl(''),
    subjectArea: new FormControl('', [Validators.minLength(2), Validators.required]),
    subjectAreaDescription: new FormControl('', [Validators.required]),
    category: new FormControl('', [Validators.minLength(2), Validators.required]),
    subCategory: new FormControl(''),
    subCategoryTwo: new FormControl(''),
    workSpace: new FormControl(''),
    reportId: new FormControl(''),
    reportName: new FormControl('', [Validators.required]),
    reportUrl: new FormControl(''),
    reportUploadData: new FormControl(''),
    image: new FormControl(''),
    externalUrl: new FormControl(false),
  });

  initializeForm(treeLayout: TreeLayout) {
    // Ad Group is being set inside of the multiselect component using the tree helper.
    // Complex case for a complex component
    // this.treeHelper.adGroupId = treeLayout.adGroupIds;
    // if(!this.treeHelper.adGroupId)
    //   return;


    if (treeLayout.subjectAreaId) {
      this.reportForm.controls.subjectArea.setValue(treeLayout.subjectAreaId);
    }

    if (treeLayout.subjectAreaDescription) {
      this.reportForm.controls.subjectAreaDescription.setValue(treeLayout.subjectAreaDescription);
      this.reportForm.controls.subjectAreaDescription.markAsDirty();
    }

    if (treeLayout.base64SubjectAreaImage) {
      this.reportForm.controls.image.setValue(treeLayout.base64SubjectAreaImage);
    }

    if (treeLayout.categoryId) {
      this.reportForm.controls.category.setValue(treeLayout.categoryId);
    }

    if (treeLayout.subCategoryId) {
      this.reportForm.controls.subCategory.setValue(treeLayout.subCategoryId);
    }

    if (treeLayout.subCategoryTwoId) {
      this.reportForm.controls.subCategoryTwo.setValue(treeLayout.subCategoryTwoId);
    }

    if (treeLayout.workSpaceId) {
      this.reportForm.controls.workSpace.setValue(treeLayout.workSpaceId);
    }

    if (treeLayout.externalUrl) {
      this.reportForm.controls.externalUrl.setValue(treeLayout.externalUrl);
    }

    if (treeLayout.externalUrl) {
      this.reportForm.controls.reportUrl.setValue(treeLayout.webLink);
    }

    if (treeLayout.reportId) {
      this.reportForm.controls.reportId.setValue(treeLayout.reportId);
    }

    if (treeLayout.reportName) {
      this.reportForm.controls.reportName.setValue(treeLayout.reportName);
    }

    this.reportForm.markAllAsTouched();

  }

  ngOnInit(): void {
    this.reportForm.controls.reportId.valueChanges.subscribe(value => {
      this.filteredReportIdDropdownValues = this._filter(value || '')
    });

    let id = '';
    let nodeType = '';

    this.route.paramMap.subscribe(params => {
      nodeType = params.get('nodeType') as string;
      id = params.get('id') as string;
    });

    forkJoin([this.httpService.getAdGroups(''), this.httpService.getBiGroups(), this.treeHelper.getTreeLayout(nodeType, id)])
      .subscribe(result => {
        this.adGroups = result[0];
        this.groupIdDropdownValues = this.groupIdDropdownValues.concat(result[1] as BiGroupDto[]);

        this.initializeForm(result[2]);
      })

    this.reportForm.controls.workSpace.valueChanges.subscribe(selectedWorkspaceId => {
      if (selectedWorkspaceId)
        this.getReportsForWorkSpace(selectedWorkspaceId);
    });

    this.reportForm.controls.subjectArea.valueChanges.subscribe(selectedSubjectAreaId => {
      if (selectedSubjectAreaId)
        this.getCategoriesForSelectedSubjectArea(selectedSubjectAreaId);

    });

    this.reportForm.controls.category.valueChanges.subscribe(selectedCategoryId => {
      if (selectedCategoryId)
        this.getSubCategoriesAndReportsForSelectedCategory(selectedCategoryId);

    });

    this.reportForm.controls.subCategory.valueChanges.subscribe(selectedSubCategoryId => {
   //   console.log('selected sub category Id', selectedSubCategoryId); // Value inside the input field as soon as it changes\
      if (selectedSubCategoryId)
        this.getSubCategoryTwosAndReportsForSelectedCategory(selectedSubCategoryId);
    });
  }

  getTreeLayout() {
  }


  getSubjectAreasOnMultiSelectedAdGroupsChange(idsString: string) {
    if (idsString)
      this.getSubjectAreasForSelectedAdGroup(idsString);
  }

  getSubjectAreasForSelectedAdGroup(adGroupIdsString: string) {
    const tempSubjectAreas: SubjectArea[] = [];

    const ids = adGroupIdsString.split(",");
    ids.forEach(id => {
      const trimmedId = id.trim();

      const adGroupsWithId = this.adGroups.filter(x => x.id == trimmedId);
      if (adGroupsWithId) {
        if (adGroupsWithId[0]?.adGroupSubjectAreaXrefs) {
          const foundSubjectAreas = adGroupsWithId[0].adGroupSubjectAreaXrefs.map(xRef => {
            return xRef.subjectArea
          });

          foundSubjectAreas.forEach(subjectArea => {
            tempSubjectAreas.push(subjectArea);
          })

        } else {
          this.subjectAreas = [];
        }
      }

    })
    this.subjectAreas = this.sortList(tempSubjectAreas);
  }

  sortList(subjectAreas: SubjectArea[]): SubjectArea[] {
    const subjectAreasSorted = subjectAreas.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1);
    return subjectAreasSorted;
  }
  getSubCategoriesAndReportsForSelectedCategory(categoryId: string) {
    // console.log('test',this.categories);
    const categoryWithId = this.categories.filter(x => x.id == categoryId);
    if (categoryWithId) {
      if (categoryWithId[0]?.subCategories)
        this.subCategories = categoryWithId[0].subCategories;
      else
        this.subCategories = [];

      if (categoryWithId[0]?.driveReports)
        this.reports = categoryWithId[0].driveReports;
      else
        this.reports = [];

    }
  }

  getSubCategoryTwosAndReportsForSelectedCategory(subCategoryId: string) {
    // console.log('test',this.categories);
    const subCategoryWithId = this.subCategories.filter(x => x.id == subCategoryId);
    if (subCategoryWithId) {
      if (subCategoryWithId[0]?.subCategoryTwos)
        this.subCategoryTwos = subCategoryWithId[0].subCategoryTwos;
      else
        this.subCategoryTwos = [];

      if (subCategoryWithId[0]?.driveReports)
        this.reports = subCategoryWithId[0].driveReports;
      else
        this.reports = [];
    }
  }

  getReportsForWorkSpace(workspaceId: string) {
    this.httpService.getBiReports(workspaceId).subscribe(data => {
      if (data && data.length > 0) {
        this.reportIdDropdownValues = data as BiReportDto[];
        this.filteredReportIdDropdownValues = this.reportIdDropdownValues;
      }

      //console.log(this.reportIdDropdownValues);
    });
  }

  selectedTabValue(event: MatTabChangeEvent) {
    const index = event.index;
    this.selectedReportTabIndex = index;
  }

  adGroupMultiSelectionChanged(idString: string) {
    //debugger;
    this.adGroupsMultiselectedIds = idString;
    this.getSubjectAreasOnMultiSelectedAdGroupsChange(idString);
  }

  adGroupSelected(adGroup: AdGroup) {
    this.selectedAdGroup = adGroup;
  }

  addNewAdGroup(name: string) {
    const request = { name: name, description: '' } as CreateAdGroupRequest;
    this.httpService.CreateAdGroup(request).subscribe(Id => {
      const newAdGroup = { id: Id, name: name } as AdGroup;

      if (!this.adGroups) this.adGroups = [];
      this.adGroups.push(newAdGroup);

      if (this.adGroupsMultiselectedIds)
        this.adGroupsMultiselectedIds = `${this.adGroupsMultiselectedIds},${Id}`;
      else
        this.adGroupsMultiselectedIds = `${Id}`;

      this.reportForm.controls.adGroup.setValue('');
      this.openSnackBar(`Success: Added AdGroup ${name}`, 'close', SnackbarModes.success);
    });
  }

  addNewSubjectArea(subjectAreaname: string) {
    const newSubjectArea = {
      adGroupIds: this.adGroupsMultiselectedIds.split(','),
      name: subjectAreaname,
      description: this.reportForm.controls.subjectAreaDescription.value ? this.reportForm.controls.subjectAreaDescription.value : null,
      image: this.reportForm.controls.image.value ? this.reportForm.controls.image.value : null,
    } as CreateSubjectAreaRequest;

    this.httpService.CreateSubjectAreaNew(newSubjectArea).subscribe(Id => {

      const newSubjectArea = {
        id: Id,
        name: subjectAreaname,

      } as SubjectArea;
      this.subjectAreas.push(newSubjectArea);

      this.reportForm.controls.subjectArea.setValue(Id);

      this.openSnackBar(`Success: Added Subject Area ${subjectAreaname}`, 'close', SnackbarModes.success);

    });
  }

  addNewCategory(categoryName: string) {
    const subjectAreaId = this.reportForm.controls.subjectArea.value as string;
    const request = { name: categoryName, subjectAreaId: subjectAreaId, description: '' } as CreateCategoryRequest;

    this.httpService.CreateCategoryNew(request).subscribe(Id => {
      console.log('Category Created', Id);

      const newCategory = { id: Id, name: categoryName, subjectAreaId: subjectAreaId } as Category;
      this.categories.push(newCategory);
      this.reportForm.controls.category.setValue(Id);
      this.openSnackBar(`Success: Added category ${categoryName}`, 'close', SnackbarModes.success);
    });
  }

  addNewSubCategory(subCategoryName: string) {
    const categoryId = this.reportForm.controls.category.value as string;
    const request = { name: subCategoryName, categoryId: categoryId, description: '' } as CreateSubCategoryRequest;

    this.httpService.CreateSubCategoryNew(request).subscribe(Id => {
      //   console.log('SubCategory Created', Id);
      const newSubCategory = { id: Id, name: subCategoryName, categoryId: categoryId } as SubCategory;
      this.subCategories.push(newSubCategory);
      this.reportForm.controls.subCategory.setValue(Id);
      this.openSnackBar(`Success: Added Subcategory ${subCategoryName}`, 'close', SnackbarModes.success);
    });
  }

  addNewSubCategoryTwo(subCategoryTwoName: string) {
    const subCategoryId = this.reportForm.controls.subCategory.value as string;
    const request = { name: subCategoryTwoName, subCategoryId: subCategoryId, description: '' } as CreateSubCategoryTwoRequest;

    this.httpService.CreateSubCategoryTwoNew(request).subscribe(Id => {
      console.log('SubCategoryTwo Created', Id);

      const newSubCategoryTwo = { id: Id, name: subCategoryTwoName, subCategoryId: subCategoryId } as SubCategoryTwo;
      this.subCategoryTwos.push(newSubCategoryTwo);
      this.reportForm.controls.subCategoryTwo.setValue(Id);
      this.openSnackBar(`Success: Added Second Subcategory ${subCategoryTwoName}`, 'close', SnackbarModes.success);
    });
  }

  getCategoriesForSelectedSubjectArea(subjectAreaId: string) {
    // console.log('categories: ',this.categories);
    const subjectAreaWithId = this.subjectAreas.filter(x => x.id == subjectAreaId);
    if (subjectAreaWithId) {
      if (subjectAreaWithId[0]?.categories) {
        this.categories = subjectAreaWithId[0].categories;
      } else {
        this.categories = []
      }
    }
  }

  updateImageBase64String(base64String: string) {
    this.tempImageBase64 = base64String;
    this.reportForm.controls.image.setValue(base64String);
    this.reportForm.controls.image.markAsDirty();
  }

  updateReportUploadBase64String(base64String: string) {
    this.reportForm.controls.reportUploadData.setValue(base64String);
    this.reportForm.controls.reportUploadData.markAsDirty();
  }

  formValid() {
    if (this.editMode)
      return true;

    const valid = (this.reportForm.controls.adGroup.dirty && this.reportForm.controls.adGroup.valid) &&
      (this.reportForm.controls.subjectArea.dirty && this.reportForm.controls.subjectArea.valid) &&
      (this.reportForm.controls.category.dirty && this.reportForm.controls.category.valid) &&
      (this.reportForm.controls.reportName.dirty && this.reportForm.controls.reportName.valid) &&
      (this.reportForm.controls.subjectAreaDescription.dirty && this.reportForm.controls.subjectAreaDescription.valid)

    let reportAreaValid = false;
    switch (this.selectedReportTabIndex) {
      case 0: {
        reportAreaValid = (this.reportForm.controls.reportId.dirty && this.reportForm.controls.reportId.valid) &&
          (this.reportForm.controls.workSpace.dirty && this.reportForm.controls.workSpace.valid);

        break;
      }

      case 1: {
        reportAreaValid = (this.reportForm.controls.reportUrl.dirty && this.reportForm.controls.reportUrl.valid);
        break;
      }
      default: {
        //statements;
        break;
      }
    }

    return valid && reportAreaValid;
  }

  clear() {
    this.reportForm.reset();
    this.selectedReportTabIndex = 0;
    this.adGroupsMultiselectedIds = '';
  }

  goToManageReports() {
    this._router.navigate(['manage']).then(() => {
      window.location.reload();
    });
  }

  save() {
    const addReportRequest: AddReportRequest = {
      adGroupId: this.adGroupsMultiselectedIds,
      subjectAreaId: this.reportForm.controls.subjectArea.value,
      base64SubjectAreaImage: this.reportForm.controls.image.value,
      subjectAreaDescription: this.reportForm.controls.subjectAreaDescription.value,
      categoryId: this.reportForm.controls.category.value,
      subCategoryId: this.reportForm.controls.subCategory.value,
      subCategoryTwoId: this.reportForm.controls.subCategoryTwo.value,
      workSpaceId: this.reportForm.controls.workSpace.value,
      reportId: this.reportForm.controls.reportId.value,
      reportName: this.reportForm.controls.reportName.value,
      fileUploadBase64: this.reportForm.controls.reportUploadData.value,
      webLink: this.reportForm.controls.reportUrl.value,
      externalUrl: this.reportForm.controls.externalUrl.value as boolean
    };

    if (!this.editMode) {
      this.httpService.CreateReportSimple(addReportRequest).subscribe(Id => {
        console.log(Id);

        this.saveSuccess.emit(true);
        this.openSnackBar(`Success: Created report ${addReportRequest.reportName}`, 'close', SnackbarModes.success);
        this.goToManageReports();
      });

    }
  }

  cancel() {
    this._router.navigate(['manage']);
  }

  openSnackBar(message: string, action: string, mode: number) {
    if (mode == SnackbarModes.success) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        panelClass: 'snackbar-style',
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    } else if (mode == SnackbarModes.error) {
      this._snackBar.openFromComponent(ErrorSnackbarComponent, {
        duration: 3000,
        panelClass: 'snackbar-style',
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }

  }

  private _filter(value: string): BiReportDto[] {
    const filterValue = value.toLowerCase();

    return this.reportIdDropdownValues.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  displayFn(options: BiReportDto[] | null) {
    return (id: string) => {
      const correspondingOption = Array.isArray(options) ? options.find(option => option.id === id) : null;
      return correspondingOption ? correspondingOption.name : '';
    }
  }
}
