import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FeaturedReport } from 'src/app/models/featuredreports';
import { NewsInsights } from 'src/app/models/newsInsights';
import { FeaturedReportsServiceService } from 'src/app/services/featured-reports-service.service';
import { NewsInsightsServiceService } from 'src/app/services/news-insights-service.service';
import { UserService } from 'src/app/services/user.service';
import { UrlViewerServiceService } from 'src/app/sharedV2/url-viewer/url-viewer-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  loginDisplay = false;
  newsAndInsights: NewsInsights[] = [];
  featuredReports: FeaturedReport[] = []; //dynamic on purpose
  driveFeatures: FeaturedReport[] = []; // static on purpose
  subscriptions: Subscription[] = [];
  constructor(private authService: MsalService, private msalBroadcastService: MsalBroadcastService, private _router: Router, private _sanitizer: DomSanitizer, private urlViewrService: UrlViewerServiceService, public userService: UserService, private _newsInsightsService:  NewsInsightsServiceService, private _featuredReportsService:  FeaturedReportsServiceService) { }

  ngOnInit(): void {
    const subscription1 =
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe(() => {
      });

      const subscription2 = this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.setDynamicContent();
      })

      this.subscriptions.push(subscription1);
      this.subscriptions.push(subscription2);
      this.driveFeatures = this.setDriveFeatureData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    })
  }

  setDynamicContent(): void {
    const adGroupId = '0C959404-BCD6-40F0-BD06-B0BEAA2EE539'; //Need to get for user
    const subscription1 = this._newsInsightsService.getNewsInsightsForAdGroup(adGroupId).subscribe(x => {
      this.newsAndInsights = x;
    });

    const subscription2 = this._featuredReportsService.getFeaturedReportsForAdGroup(adGroupId).subscribe(x => {
      this.featuredReports = x;
    });

    this.subscriptions.push(subscription1);
    this.subscriptions.push(subscription2);
  }

  setDriveFeatureData(): NewsInsights[] {
    // Drive Features are static on purpose.
    // Featured reports are dynamic

    const data = [] as NewsInsights[];

    const n1 = {
      url: 'https://www.ajg.com/us/gallagher-drive/',
      callToAction: 'Access our external Gallagher Drive Site',
      description: 'Compare your insurance program structures against industry peers and make more informed purchasing decisions around your coverages, your limits and potential exposures.',
      title:  'Benchmarking Services'

    } as NewsInsights;

    const n2 = {
      url: 'https://www.ajg.com/us/gallagher-drive/',
      callToAction: 'Access Market Dashboards',
      description: 'Gallagher has decades of experience working with carriers complemented with unique trade data to help ensure you optimize your insurance panel along with your Total Cost of Risk (TCOR). Review carriers and market rates to ensure risk is being managed with the right carrier.',
      title:  'Placement Analytics'

    } as NewsInsights;

    const n3 = {
      url: 'https://gallagherdrive-dev.ajg.com/#/report-viewer/42cb151b-4586-418f-b0e8-61326c2ed4ba/f30c8a5d-456b-41f1-a244-c59c98b827c4/af51d32a-b612-4f22-81d6-aa2f69c41b26',
      callToAction: 'Access NRC Claim Analytics',
      description: "Gallagher's proprietary data & analytics combined with our National Risk Control (NRC) team can help you better analyze your organization's loss history and related impacts. ",
      title:  'Claims Analytics'

    } as NewsInsights;

    const n4 = {
      url: 'https://forms.office.com/Pages/DesignPageV2.aspx?origin=NeoPortalPage&subpage=design&id=cNGsbJf4GUusWEaiMwe4Chl49RgbXlRLp86zeABWqwxUQ1NUMFk5RVE0MlJDMFA2MFkyV0RYQVBROS4u',
      callToAction: 'Connect with a Drive Analytics Consultant',
      description: 'Our comprehensive suite of data visualizations and dashboards provide a visual story of the factors that comprise your unique Total Cost of Risk (TCOR) and our analytics consultants can show how they work together to deliver the performance your organization requires.',
      title:  'Expert Consulting'

    } as NewsInsights;

    data.push(n1,n2,n3,n4);
   return data;
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }


    navigateToUrl(url: string, reportId: string) {
      this.urlViewrService.setLatestUrl(url, reportId);
      this._router.navigate(['url-viewer']).then(() => {
        window.location.reload();
        });
    }

    routeToAjg() {
      window.open('https://www.ajg.com/us/gallagher-drive/', "_blank");

    }
    routeToOther() {
      window.open('https://forms.office.com/Pages/DesignPageV2.aspx?origin=NeoPortalPage&subpage=design&id=cNGsbJf4GUusWEaiMwe4Chl49RgbXlRLp86zeABWqwxUQ1NUMFk5RVE0MlJDMFA2MFkyV0RYQVBROS4u', "_blank");
    }

    routeToUrl(url: string) {

      window.open(url, "_blank");

    }

    goToLink(reportUrl: string, reportUrlAssociatedId: string){
      const subscription1 = this.userService.AddUrlVisit(reportUrl, reportUrlAssociatedId).subscribe(x => {
        this.userService.addToVisitListLocal(x);
      });
      this.subscriptions.push(subscription1);
      window.open(reportUrl, "_blank");
    }

    navigateToReportView(workspaceId: string, powerBiReportId: string, reportId: string) {
        this._router.navigate(['report-viewer', workspaceId, powerBiReportId, reportId]).then(() => {
          window.location.reload();
          });

     }
}
